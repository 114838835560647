import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { fromStore } from '../../../selectors';
import cn from 'classnames';
import Loader from '../../../components/utils/Loader';
import { isEmpty } from 'lodash';
import { ItemRow } from './ItemRow';
import { TableHeader } from './TableHeader';
import { useDispatch } from 'react-redux';
import { fetchKassaAccounts } from '../../../action';
import ReactTooltip from 'react-tooltip';
import { styles } from '../styles';
import { setUIvisible } from '../../../action/UImobile';

export function KassaAccountsList() {
    const dispatch = useDispatch();
    const loaderList = useSelector(fromStore.loaderKassaSelector);
    const accounts = useSelector(fromStore.kassaListSelector);
    const UImobile = useSelector(state => state.UImobile);

    useEffect(() => ReactTooltip.rebuild(), [accounts, loaderList]);

    useEffect(() => {
        dispatch(fetchKassaAccounts());
        dispatch(setUIvisible(false, true))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    return (
        <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid" style={{ padding: 0 }}>
            <div className="kt-portlet kt-portlet--mobile">
                <div className="kt-portlet__body kt-portlet__body--fit">
                    <div
                        style={{ position: 'relative', marginBottom: 0 }}
                        className={
                            cn('kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded', {
                                'kt-datatable--error': isEmpty(accounts)
                            })
                        }>
                        {loaderList && <div style={styles.tableLoading}><Loader text='Загружаю данные'/></div>}
                        <table className="kt-datatable__table" style={{ minHeight: '700px' }}>
                            {window.innerWidth >= 950 && <TableHeader UImobile={UImobile}/>}
                                <tbody className="kt-datatable__body">
                                    {
                                        loaderList
                                            ? null
                                            : isEmpty(accounts)
                                                ? <tr><td colSpan={8}><span className="kt-datatable--error">Данных пока нет</span></td></tr>
                                                : accounts.map((item) => <ItemRow key={item.id} item={item} UImobile={UImobile}/>)
                                    }
                                </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <ReactTooltip
                effect="solid"
                type="dark"
                place="top"
            />
        </div>
    );
}