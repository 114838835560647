import React, { Component, Fragment } from 'react';
import ErrorContainer from '../components/utils/ErrorContainer';
import Portlet from '../components/utils/Portlet';
import cn from 'classnames';

import Refferers from '../components/statistics/Refferers';
import PopGoods from '../components/statistics/PopGoods';
import OrderStats from '../components/statistics/OrderStats';
import Promocode from '../components/statistics/Promocode';
import Clients from '../components/statistics/Clients';
import Points from '../components/statistics/Points';
import { connect } from 'react-redux';
import { setUIvisible } from '../action/UImobile';

const statisticsComponents = {
    refferers: { title: 'Рефельная программа', component: Refferers },
    popGoods: { title: 'Популярные товары', component: PopGoods },
    orders: { title: 'Заказы', component: OrderStats },
    promocode: { title: 'Промокоды', component: Promocode },
    clients: { title: 'Клиенты', component: Clients },
    points: { title: 'Филиалы', component: Points }
};

function EmptyPage() {
    return (
        <Portlet >
            <div style={{
                minHeight: 250,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: 18
             }}>
                <div>Выберите необходимый раздел</div>
            </div>
        </Portlet>
    );
}


class StatisticPage extends Component {
    constructor(props) {
        super(props);
        this.scrollRef = React.createRef();
        this.state = {
            hasScrollLeft: false,
            hasScrollRight: false,
        };
    }

    state = {
        mode: null
    }

    componentDidMount(){
        this.props.setUIvisible(false, false);
        this.updateScrollIndicators();
        window.addEventListener('resize', this.updateScrollIndicators);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateScrollIndicators);
    }

    updateScrollIndicators = () => {
        const { current } = this.scrollRef;
        if (current) {
            const scrollLeft = current.scrollLeft;
            const scrollWidth = current.scrollWidth;
            const clientWidth = current.clientWidth;

            this.setState({
                hasScrollLeft: scrollLeft > 0,
                hasScrollRight: scrollLeft + clientWidth < scrollWidth,
            });
        }
    };

    renderSubHeader() {
        return (
            <div className="kt-subheader not-mt kt-grid__item">
                <div className="kt-container  kt-container--fluid">
                <div className="kt-subheader__main">
                    <h3 className="kt-subheader__title">Статистика</h3>
                    <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                    <div className="kt-subheader__group" id="kt_subheader_search">
                       
                    </div>
                </div>
            </div>
            </div>
        );
    }

    onClickItem = (mode) => (e) => {
        this.setState({ mode });
    }

    render() {
        const StaticticsComponent = this.state.mode ? statisticsComponents[this.state.mode].component : EmptyPage;
        const { hasScrollLeft, hasScrollRight } = this.state;

        return (
            <Fragment>
                { this.renderSubHeader() }
                <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                    <ErrorContainer field="statistic" style={{ margin: 15 }} hasCloseButton={true}/>
                    <div className="kt-grid kt-grid--desktop kt-grid--ver-desktop  kt-todo" id="kt_todo">
                        <div className="kt-grid__item   kt-portlet kt-todo__aside statistic" id="kt_todo_aside" style={{ opacity: 1 }}>
                            <div className="kt-todo__nav statistic">
                                {hasScrollLeft && 
                                    <div style={{
                                        position: 'absolute',
                                        left: 0,
                                        top: 0,
                                        bottom: 0,
                                        width: '10px',
                                        background: 'linear-gradient(to right, rgba(0, 0, 0, 0.23), rgba(0, 0, 0, 0))',
                                        zIndex: 1
                                    }} />
                                }
                                <ul 
                                className="kt-nav statistic"
                                ref={this.scrollRef}
                                onScroll={this.updateScrollIndicators}
                                >
                                    {
                                        Object.keys(statisticsComponents).map(key => (
                                            <li key={key} onClick={this.onClickItem(key)} className={cn({ 'kt-nav__item': true, 'kt-nav__item--active': key === this.state.mode })}>
                                                <span className="kt-nav__link statistic" data-action="list">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon kt-nav__link-icon statistic">
                                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                            <rect x="0" y="0" width="24" height="24"/>
                                                            <path d="M12,21 C7.02943725,21 3,16.9705627 3,12 C3,7.02943725 7.02943725,3 12,3 C16.9705627,3 21,7.02943725 21,12 C21,16.9705627 16.9705627,21 12,21 Z M12,18 C15.3137085,18 18,15.3137085 18,12 C18,8.6862915 15.3137085,6 12,6 C8.6862915,6 6,8.6862915 6,12 C6,15.3137085 8.6862915,18 12,18 Z" fill="#000000"/>
                                                            <path d="M12,16 C14.209139,16 16,14.209139 16,12 C16,9.790861 14.209139,8 12,8 C9.790861,8 8,9.790861 8,12 C8,14.209139 9.790861,16 12,16 Z" fill="#000000" opacity="0.3"/>
                                                        </g>
                                                    </svg>
                                                    <span className="kt-nav__link-text statistic">{statisticsComponents[key].title}</span>
                                                </span>
                                            </li>
                                        ))
                                    }
                                </ul>
                                {hasScrollRight && 
                                    <div style={{
                                        position: 'absolute',
                                        right: 0,
                                        top: 0,
                                        bottom: 0,
                                        width: '10px',
                                        background: 'linear-gradient(to left, rgba(0, 0, 0, 0.23), rgba(0, 0, 0, 0))',
                                        zIndex: 1
                                    }} />
                                }
                            </div>
                        </div>
        
                        <div className="kt-grid__item kt-grid__item--fluid kt-todo__content" id="kt_todo_content" style={{ flex: '1 auto !important' }}>
                            <div className="kt-todo__tasks-bottom">
                                <StaticticsComponent />
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}


const mapDispatchToProps = {
    setUIvisible: (visibleFiltersButton, visibleActionButton) => setUIvisible(visibleFiltersButton, visibleActionButton)
}

export default connect(null, mapDispatchToProps)(StatisticPage);