import { urls, baseUrl, roleId } from '../constants/app';
import moment from 'moment';
import request from './utils';
import axios from 'axios';
import { setError, resetError } from './errors';
axios.defaults.baseURL = baseUrl;

export function fetchGoods() {
    return (dispatch, getState) => {
    const goods = getState().goods.list;
    if (goods.length === 0) {
        dispatch({ type: 'SET_LOADER', field: 'goods', value: true });
        return axios.get('https://xn--161-9cd8an1avep9c.xn--p1ai/api/items')
            .then((response) => {
                dispatch({ type: 'SET_GOODS', goods: response.data });
                dispatch({ type: 'SET_LOADER', field: 'goods', value: false });
                return response;
            })
            .catch(error => {
                dispatch(setError(error, 'global'));
                dispatch({ type: 'SET_LOADER', field: 'goods', value: false });
                return null
            });
        }
    }
}


export function fetchCategories() {
    return (dispatch, getState) => {
    const categories = getState().goods.categories;
    if (categories.length === 0) {
        dispatch({ type: 'SET_LOADER', field: 'categories', value: true });
        return axios.get('https://xn--161-9cd8an1avep9c.xn--p1ai/api/category')
            .then((response) => {
                dispatch({ type: 'SET_CATEGORIES', categories: response.data });
                dispatch({ type: 'SET_LOADER', field: 'categories', value: false });
                return response;
            })
            .catch(error => {
                dispatch(setError(error, 'global'));
                dispatch({ type: 'SET_LOADER', field: 'categories', value: false });
                return null
            });
        }
    }
}


export function fetchOrders(dataParams = {}) {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_LOADER', field: 'orders', value: true });
        const paging = getState().orders.paging;
        const sorting = getState().orders.sorting;
        const user = getState().user.info;
        const filters = getState().orders.filters;

        if(!filters.createdEndDate)    
            filters.createdEndDate = new Date().setHours(23, 59, 59, 999)
        
        if(!filters.createdStartDate)
            filters.createdStartDate = new Date().setHours(0, 0, 0, 0)

        const params = {
            ...dataParams
        };

        if (filters.city) {
            dataParams['city'] = filters.city.value;//передается city, а не city.id, тк на стороне api идет sql запрос, а point это связь, а не данные
            params['city'] = filters.city.value;
        }

        if (filters.zone) {
            dataParams['zone_null'] = false;
            params['zone_null'] = false;
        }

        if (filters.phone) {
            dataParams['phone'] = filters.phone;
            params['phone'] = filters.phone;
        }

        if (filters.id) {
            dataParams['id'] = filters.id;
            params['id'] = filters.id;
        }

        if (filters.payment && filters.payment.value) {
            dataParams['payment'] = filters.payment.value;
            params['payment'] = filters.payment.value;
        }

        if (filters.name) {
            dataParams['userName'] = filters.name;
            params['userName'] = filters.name;
        }

        if (filters.status) {
            dataParams['status'] = filters.status.value;
            params['status'] = filters.status.value;
        }

        if (filters.createdStartDate) {
            dataParams['created_at_gte'] = moment(filters.createdStartDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
            params['created_at_gte'] = moment(filters.createdStartDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
        }

        if (filters.createdEndDate) {
            dataParams['created_at_lte'] = moment(filters.createdEndDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
            params['created_at_lte'] = moment(filters.createdEndDate).subtract({hours:3}).format('YYYY-MM-DD HH:mm:ss');
        }

        if(params.user) {
            dataParams['created_at_gte'] = null;
            params['created_at_gte'] = null;
            dataParams['created_at_lte'] = null;
            params['created_at_lte'] = null;
        }

        params['_start'] = paging.start;
        params['_limit'] = paging.limit;
        params['_sort'] = `${sorting.field}:${sorting.type}`;

        if(user.points.length > 0  || filters.poin) {
            let pointsId = null;

            if(!filters.point) {
                pointsId = user.points.map(item => item.id);
            } else {
                pointsId = filters.point.value;
            }
            
            dataParams['pointsId'] = pointsId;
            params['pointsId'] = pointsId;
        }

        if(!params.user && user.role.id === roleId.admin)
            request({ method: 'get', url: urls.orders+'/stats-orders-of-head', params: dataParams })
                .then(result => {
                    dispatch({ type: 'SET_STATISTICS', name: 'ordersOfHead', result });
                    dispatch({ type: 'SET_STATISTICS_LOADER', name: 'ordersOfHead', loading: false });
                })
                .catch(error => {
                    dispatch({ type: 'SET_LOADER', name: 'ordersOfHead', loading: false });
                });
            
        request({ method: 'get', url: urls.orders+'/count/crm', params: dataParams })
            .then(result => {
                dispatch({ type: 'SET_ORDERS_COUNT', count: result });
                return;
            })
            .then(async () => {
            const cities = getState().handbook.cities;
            const zones = getState().handbook.zones;

            return request({ method: 'get', url: urls.orders+'/crm', params }).then(async (response) => {
                const orders = await Promise.all(response.map(async (order) => {
                    if(order.city && !order.city.id) {
                        let checkCity = cities.find((city) => city.id === order.city)
                        if(checkCity) {
                            order.city = checkCity;
                        } else {
                            request({ method: 'get', url: `${urls.cities}/${order.city}` })
                            .then((response) => order.city = response);
                        }
                    }

                    if(order.point && !order.point.id) {
                        let checkPoint = user.points.find((point) => point.id === order.point);
                        if(checkPoint) {
                            order.point = checkPoint;
                        } else {
                            await request({ method: 'get', url: `${urls.filials}/${order.point}` })
                            .then((response) => order.point = response);
                        }
                    }

                    if(order.zone && !order.zone.id) {
                        let checkZone = zones.find((zone) => zone.id === order.zone);
                        if(checkZone) {
                            order.zone = checkZone;
                        } else {
                            await request({ method: 'get', url: `${urls.zone}/${order.zone}` })
                            .then((response) => order.zone = response);
                        }
                    }

                    order.goods = JSON.parse(order.goods)
                    order.cashback = JSON.parse(order.cashback)

                    return order;                            
                }));
                dispatch({ type: 'SET_ORDERS', orders: orders });
                dispatch({ type: 'SET_LOADER', field: 'orders', value: false });
            })
        })
        .catch(error => {
            dispatch(setError(error, 'orders'));
            dispatch({ type: 'SET_LOADER', field: 'orders', value: false });
        });
    }
}

export function deleteOrder(id) {
    return (dispatch) => {
    dispatch({ type: 'SET_LOADER', field: 'orders', value: true });
    dispatch(resetError('orders'));
    return request({ method: 'delete', url: `${urls.orders}/${id}` })
        .then((response) => {
            dispatch({ type: 'SET_LOADER', field: 'orders', value: false });
            return true;
        })
        .catch(error => {
            dispatch(setError({ status: 507 }, 'orders'));
            dispatch({ type: 'SET_LOADER', field: 'orders', value: false });
            return false;
        });
    }
}

export function setOrdersFilters(filters) {
    return dispatch => {
        dispatch({ type: 'SET_ORDERS_FILTERS', filters });
    }
}

export function updateOrder(id, data) {
    return (dispatch) => {
    dispatch({ type: 'SET_LOADER', field: 'order', value: true });
    dispatch(resetError('orders'));
    return request({ method: 'put', url: `${urls.orders}/${id}`, data })
        .then((response) => {
            dispatch({ type: 'SET_ORDER', order: response });
            dispatch({ type: 'SET_LOADER', field: 'order', value: false });
            return true;
        })
        .catch(error => {
            dispatch(setError({ status: 508 }, 'orders'));
            dispatch({ type: 'SET_LOADER', field: 'order', value: false });
            return false;
        });
    }
}

export function updateOrderFromTable(id, data, isArchive) {
    return (dispatch) => {
    const url = isArchive ? urls.archive : urls.orders;
    dispatch(resetError('orders'));
    dispatch({ type: 'SET_LOADER', field: 'updateorder', value: id });

        return request({ method: 'put', url: `${url}/${id}`, data })
            .then(() => {
                dispatch({ type: 'UPDATE_ORDER_POINT', id: id, point: data.point, zone: data.zone, city: data.city, status: data.status });
                dispatch({ type: 'SET_LOADER', field: 'updateorder', value: false });
                return true;
            })
            .catch(error => {
                dispatch(setError({ status: 508 }, 'orders'));
                dispatch({ type: 'SET_LOADER', field: 'updateorder', value: false });
                return false;
            });
        }
    }

export function fetchOrder(id) {
    return (dispatch) => {
    dispatch({ type: 'SET_LOADER', field: 'order', value: true });

    return request({ method: 'get', url: `${urls.orders}/${id}` })
        .then((response) => {
            dispatch({ type: 'SET_ORDER', order: response });
            dispatch({ type: 'SET_LOADER', field: 'order', value: false });
            return response;
        })
        .catch(error => {
            dispatch(setError(error, 'order'));
            dispatch({ type: 'SET_LOADER', field: 'order', value: false });
            return null
        });
    }
}

export function setOrdersPaging(paging) {
    return dispatch => {
        dispatch({ type: 'SET_ORDERS_PAGING', paging });
    }
}