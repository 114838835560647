import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addKassaAccount, setActiveModalAction, updateKassaAccount } from '../../action';
import { useOnClickOutside } from '../../hooks';
import { fromStore, modalsSelector } from '../../selectors';
import cn from 'classnames';
import ErrorContainer from '../../components/utils/ErrorContainer';

function YooKassaModal() {
  const ref = useRef();
  const dispatch = useDispatch();
  const id = useSelector(modalsSelector).yookassa;
  const loading = useSelector(fromStore.loaderAddKassaSelector);
  const loadingUpdate = useSelector(fromStore.loaderUpdateKassaSelector);
  const account = useSelector(fromStore.kassaById(id));

  const loader = loading || loadingUpdate;

  const initData = account ? {
    name: account.name,
    secret: account.secret,
  } : {
    name: '',
    secret: '',
  };
  const [data, setData] = useState(initData);
  const isEditMode = id !== 'new';

  const onChangeInput = (field) => (e) => {
    const updated = {...data};
    updated[field] = e.target.value;
    setData(updated);
  };

  const isValid = () => {
    const { name, secret } = data;
    if (isEditMode) {
      if (name || secret) return true;
    }
    if (name && secret) return true;

    return false;
  }

  const onClose = () => !loader && dispatch(setActiveModalAction({ field: 'yookassa', value: null }));
  useOnClickOutside(ref, onClose);

  const onSubmit = async (e) => {
    e.preventDefault();
    const rq = { ...data };
    if (!isEditMode) {
      const result = await dispatch(addKassaAccount(rq));
      if (result) {
        onClose();
      }
    } else {
      const result = await dispatch(updateKassaAccount(id, rq));
      if (result) {
        onClose();
      }
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <div  className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
          <div ref={ref} className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{isEditMode ? 'Редактирование аккаунта' : 'Добавление аккаунта'} </h5>
              <button onClick={onClose} type="button" className="close">
              </button>
            </div>
            <div className="modal-body">
                <div className="form-group">
                  <label htmlFor="g-name" className="form-control-label">Название *</label>
                  <input disabled={loader} type="text" value={data.name} className="form-control" id="g-name" onChange={onChangeInput('name')} />
                </div>
                <div className="form-group">
                  <label htmlFor="g-name" className="form-control-label">Секретный ключ *</label>
                  <input disabled={loader} type="text" value={data.secret} className="form-control" id="g-name" onChange={onChangeInput('secret')} />
                </div>
  
                <ErrorContainer field="addKassa" hasCloseButton={true}/>
            </div>

            <div className="modal-footer">
              <button disabled={loader} type="button" className="btn btn-secondary" onClick={onClose}>Отмена</button>
              <button
                type="submit"
                disabled={!isValid() || loader}
                className={cn({ 'btn btn-primary  kt-spinner--right kt-spinner--sm kt-spinner--light': true, 'kt-spinner': loader })}>
                  {isEditMode ? 'Сохранить' : 'Добавить аккаунт'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default YooKassaModal;