import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Input from '../utils/Input';
import { setFilialEdit, fetchCities, fetchFilial, updateFilial, removeUserPoint } from '../../action/handbook';
import cn from 'classnames';
import Loader from '../utils/Loader';
import { isEqual } from 'lodash';
import ErrorContainer from '../utils/ErrorContainer';
import { YMaps, Map, SearchControl, Placemark } from 'react-yandex-maps';
import { createSelector } from 'reselect'
import Select from 'react-select';
import { fetchGoods, fetchCategories } from '../../action/goods';
import { fetchKassaAccounts } from '../../action/yookassa';
import Switch from '../utils/Switch';
import { optionsMinDeliveryTime, optionsMinSamTime, roleId, timeDelivery } from '../../constants/app'
import { setUIvisible } from '../../action/UImobile';

const styles = {
    loading: {
        minHeight: '500px',
        display: 'flex',
        justifyContent: 'center'
    }
}

const activateSelect = {
    'active': 'Активирован',
    'disabledAll': 'Полностью остановлен',
    'disabledDelivery': 'Доставка остановлена',
    'disabledOwn': 'Самовывоз остановлен',
};

class EditFilial extends Component {
    componentDidMount() {
        this.props.fetchCities();
        this.props.fetchKassaAccounts();
        this.props.fetchFilial(this.props.match.params.id).then(result => {
            this.props.setFilialEdit(result);
        });
        this.props.fetchGoods();
        this.props.fetchCategories();
        this.props.setUIvisible(false ,false);
    }

    componentWillUnmount() {
        this.props.setFilialEdit(null);
    }
    
    renderSubHeader() {
        const { state } = this.props.location;
        return (
            <div className="kt-subheader not-mt kt-grid__item">
                <div className="kt-container  kt-container--fluid ">
                <div className="kt-subheader__main">
                    <h3 className="kt-subheader__title">Редактирование филиала</h3>
                    <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                    <div className="kt-subheader__group" id="kt_subheader_search">
                        <span className="kt-subheader__desc">
                            
                        </span>
                    </div>
                </div>        
                <div className="kt-subheader__toolbar">
                    <button onClick={() => this.props.history.push(state && state.city && state.from === 'edit' ? `/handbook/cities/edit/${state.city}` : '/handbook/filials' )} className="btn btn-default btn-bold">
                        Назад
                    </button>
                </div>
            </div>
            </div>
        );
    }

    isValidFilial() {
        const { city, street, house, email, phone, timeWork, coord, minDeliveryTime, minSamTime  } = this.props.edit;
        if (isEqual(this.props.detail, this.props.edit)) return false;
        if (!city || !street || !house || !email || !phone || !timeWork || !coord || !minDeliveryTime || !minSamTime) return false;

        return true;
    }

    setCity = (field) => (e) => {
        const data = {...this.props.edit};
        
        if (field === 'minDeliveryTime' || field === 'minSamTime') {
            data[field] = e.value;
        }

        if(field === 'hiddenClient') {
            data.minDeliveryTime = timeDelivery.minDeliveryTime
            data.minSamTime = timeDelivery.minSamTime
            data.activate = 'disabledAll'
        }   

        if(field === 'promotion' && e === false)
            data.promotionDescription = null

        if (field === 'onlinePay' 
            || field === 'stopList' 
            || field === 'stop' 
            || field === 'directOrder' 
            || field === 'disableLoyalSystem' 
            || field === 'onlineDirectOrder'
            || field === 'hiddenClient'
            || field === 'qrPayment'
            || field === 'deliveryToTheEntrance'
            || field === 'cafe'
            || field === 'promotion') {
            data[field] = e;
        } else if (field !== 'minDeliveryTime' && field !== 'minSamTime') {
            data[field] = e.target.value;
        }
        this.props.setFilialEdit(data);
    }

    setFields = (fields) => {
        const data = {...this.props.edit};

        for (let field in fields) {
            data[field] = fields[field];
        }

        this.props.setFilialEdit(data);
    }

    setOnlinePay = (value) => {
        if(value) {
            this.setCity('onlinePay')(value);
        } else {
            const fields = {
                onlinePay: value,
                onlineDirectOrder: value
            }
            this.setFields(fields);
        }
    }

    setDirectOrder = (value) => {
        if(value && this.props.edit.disableLoyalSystem) {
            const fields = {
                directOrder: value,
                disableLoyalSystem: !value
            }
            this.setFields(fields)
        } else {
            this.setCity('directOrder')(value)
        }
    }

    setDisableLoyalSystem = (value) => {
        if(value && this.props.edit.directOrder) {
            const fields = {
                directOrder: !value,
                disableLoyalSystem: value
            }
            this.setFields(fields)
        } else {
            
            this.setCity('disableLoyalSystem')(value)
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
        const dat = { ...this.props.edit };
        delete dat.users;

        this.props.updateFilial(this.props.match.params.id, dat).then(result => {
            if (result) {
                this.props.history.push('/handbook/filials');
            }
        });
    }

    handleRemoveUserPoints = (e) => {
        e.preventDefault();
        this.props.removeUserPoint(this.props.edit.id).then(result => {
            if (result) {
                this.props.history.push(`/handbook/filials`)
            }
        });
    }

    onResultShow = () => {
        if (this.map) {
            const index = this.map.getSelectedIndex();
            const result = this.map.getResultsArray();
            const coord = result[index].geometry.getCoordinates().join(',');
            this.setCoord(coord);
          }
    };

    setCoord = (value) => {
        const data = {...this.props.edit};
        data['coord'] = value;
        this.props.setFilialEdit(data);
    }

    renderOnlinePayment = () => {
        const { role, edit } = this.props;
        if (![roleId.admin].includes(role.id)) return null;
        
        return (
            <div className='online-pay_block'>
                 <div className="row">
                    <div className="col-md-4">
                        <div className="row">
                            <div className="col-md-6">
                                <label className="col-10 col-form-label">Онлайн-оплата</label>
                                <div className="col-2">
                                    <Switch 
                                        onChange={(value) => this.setOnlinePay(value)} 
                                        checked={edit.onlinePay} 
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <label className="col-10 col-form-label">Прямой заказ онлайн</label>
                                <div className="col-2">
                                    <Switch 
                                        onChange={this.setCity('onlineDirectOrder')} 
                                        checked={edit.onlineDirectOrder}
                                        disabled={!edit.onlinePay}
                                    />
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <Input
                            label="ФИО ИП"
                            onChange={this.setCity('legalName')}
                            value={edit.legalName}
                            placeholder="ФИО ИП..."
                        />
                    </div>
                    <div className="col-md-4">
                        <Input
                            label="ИНН"
                            onChange={this.setCity('inn')}
                            value={edit.inn}
                            placeholder="ИНН..."
                        />
                    </div>
                    <div className="col-md-4">
                        <Input
                            label="ОГРНИП"
                            onChange={this.setCity('ogrnip')}
                            value={edit.ogrnip}
                            placeholder="ОГРНИП..."
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8">
                        <Input
                            label="Почтовый адрес"
                            onChange={this.setCity('legalAdress')}
                            value={edit.legalAdress}
                            placeholder="Почтовый адрес..."
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                            <label>Аккаунт Юкассы</label>
                            <select value={edit && edit.yookassa} onChange={this.setCity('yookassa')} className="form-control">
                                <option value="">Выберите аккаунт</option>
                                {
                                    this.props.kassaAccounts.map((item, index) => (
                                        <option key={index} value={item.id}>{item.name} - {item.secret}</option>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <Input
                            label="SHOP_ID"
                            onChange={this.setCity('shopId')}
                            value={edit.shopId}
                            placeholder="SHOP_ID..."
                        />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { edit } = this.props;
        if (this.props.loading || this.props.cities.length === 0 || !edit) {
            return (
                <div style={styles.loading}><Loader /></div>
            );
        }
            
        return (
            <Fragment>
                { this.renderSubHeader() }
                <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                    
                <div className="kt-portlet">
                    <form onSubmit={this.onSubmit} className="kt-form">
                        <div className="kt-portlet__body">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Город *</label>
                                        <select disabled={true} value={edit.city.id} onChange={this.setCity('city')} className="form-control">
                                            <option value="">Выберите город</option>
                                            {
                                                this.props.cities.map((item, index) => (
                                                    <option key={index} value={item.id}>{item.name}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                {[roleId.admin].includes(this.props.role.id) && 
                                <div className="col-md-4">
                                    <Input
                                        label="Frontpad ID"
                                        onChange={this.setCity('frontpadId')}
                                        value={this.props.edit.frontpadId}
                                        placeholder="ID..."
                                    />
                                </div>
                                }
                                {[roleId.admin].includes(this.props.role.id) && 
                                <div className="col-md-4">
                                    {/*
                                    <div className="form-group row" style={{ marginTop: '25px' }}>
                                        <label className="col-4 col-form-label">Выключен</label>
                                        <div className="col-8" style={{ textAlign: 'right' }}>
                                            <Switch onChange={(value) => this.setCity('stop')(value)} checked={this.props.edit.stop}/>
                                        </div>
                                    </div>*/}
                                    <Input
                                        required={true}
                                        label="Время работы"
                                        onChange={this.setCity('timeWork')}
                                        value={this.props.edit.timeWork}
                                        placeholder="Время работы..."
                                    />
                                </div>
                                }
                            </div>
                            {[roleId.admin].includes(this.props.role.id) && 
                            <div className="row">
                                <div className="col-md-4">
                                    <Input
                                        label="Улица"
                                        required={true}
                                        onChange={this.setCity('street')}
                                        value={this.props.edit.street}
                                        placeholder="Улица..."
                                    />
                                </div>
                                <div className="col-md-4">
                                    <Input
                                        label="Дом"
                                        required={true}
                                        onChange={this.setCity('house')}
                                        value={this.props.edit.house}
                                        placeholder="Дом..."
                                    />
                                </div>
                                <div className="col-md-4">
                                    <Input
                                        label="Подъезд"
                                        onChange={this.setCity('entrance')}
                                        value={this.props.edit.entrance}
                                        placeholder="Подъезд..."
                                    />
                                </div>
                            </div>
                            }
                             {[roleId.admin].includes(this.props.role.id) && 
                            <div className="row">
                                <div className="col-md-4">
                                    <Input
                                        label="Квартира / офис"
                                        onChange={this.setCity('room')}
                                        value={this.props.edit.room}
                                        placeholder="Квартира..."
                                    />
                                </div>
                                <div className="col-md-4">
                                    <Input
                                        required={true}
                                        label="Email"
                                        onChange={this.setCity('email')}
                                        value={this.props.edit.email}
                                        placeholder="Email..."
                                    />
                                </div>
                                <div className="col-md-4">
                                    <Input
                                        required={true}
                                        label="Телефон"
                                        onChange={this.setCity('phone')}
                                        value={this.props.edit.phone}
                                        placeholder="Телефон..."
                                    />
                                </div>
                            </div>
                            }
                            {this.renderOnlinePayment()}
                            <div className="row">
                                <div className="col-md-4">
                                    <label>{"Минимальное время доставки *"}</label>
                                    <Select
                                        isDisabled={this.props.edit.activate === 'disabledAll' || this.props.edit.activate === 'disabledDelivery' || this.props.edit.hiddenClient}
                                        menuPortalTarget={document.body}
                                        closeMenuOnSelect={true}
                                        options={optionsMinDeliveryTime}
                                        onChange={this.setCity('minDeliveryTime')}
                                        value={{value: this.props.edit.minDeliveryTime, label: this.props.edit.minDeliveryTime}}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <label>{"Минимальное время самовывоза *"}</label>
                                    <Select
                                        sDisabled={this.props.edit.activate === 'disabledAll' || this.props.edit.activate === 'disabledOwn' || this.props.edit.hiddenClient}
                                        menuPortalTarget={document.body}
                                        closeMenuOnSelect={true}
                                        options={optionsMinSamTime}
                                        onChange={this.setCity('minSamTime')}
                                        value={{value: this.props.edit.minSamTime, label: this.props.edit.minSamTime}}
                                    />
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <label>Стоп лист товаров</label>
                                        <Select
                                            isLoading={this.props.loadingGoods}
                                            isDisabled={this.props.loadingGoods}
                                            menuPortalTarget={document.body}
                                            isMulti
                                            isSearchable
                                            closeMenuOnSelect={true}
                                            options={this.props.goods}
                                            placeholder="Товары"
                                            noOptionsMessage={() => 'Нет товаров'}
                                            onChange={this.setCity('stopList')}
                                            value={this.props.edit.stopList}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="form-group row">
                                        <label className="col-12 col-form-label">Доступность</label>
                                        <div className="col-12">
                                            <select disabled={this.props.edit.hiddenClient}  onChange={(e) => this.setCity('activate')(e)} value={this.props.edit.activate} className="form-control">
                                                {
                                                    Object.keys(activateSelect).map((key, index) => (
                                                        <option key={key} value={key}>{activateSelect[key]}</option>
                                                    ))
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {[roleId.admin].includes(this.props.role.id) &&
                                <>
                                    <div className="col-md-4">
                                        <div className="form-group row">
                                            <label className="col-12 col-form-label">Прямой заказ</label>
                                            <div className="col-12">
                                                <Switch onChange={(value) => this.setDirectOrder(value)} checked={this.props.edit.directOrder} />
                                            </div>
                                        </div>                                    
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group row">
                                            <label className="col-12 col-form-label">Игнорировать систему лояльности</label>
                                            <div className="col-12">
                                                <Switch onChange={(value) => this.setDisableLoyalSystem(value)} checked={this.props.edit.disableLoyalSystem} />
                                            </div>
                                        </div>
                                    </div>                                
                                    <div className="col-md-4">
                                        <div className="form-group row">
                                            <label className="col-12 col-form-label">Скрыт для клиентов</label>
                                            <div className="col-12">
                                                <Switch onChange={this.setCity('hiddenClient')} checked={this.props.edit.hiddenClient} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                                }
                                <div className="col-md-4">
                                    <div className="form-group row">
                                        <label className="col-12 col-form-label">Есть оплата QR-кодом</label>
                                        <div className="col-12">
                                            <Switch onChange={this.setCity('qrPayment')} checked={this.props.edit.qrPayment} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group row">
                                        <label className="col-12 col-form-label">Доставка до подъезда</label>
                                        <div className="col-12">
                                            <Switch onChange={this.setCity('deliveryToTheEntrance')} checked={this.props.edit.deliveryToTheEntrance} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className='form-group'>
                                        <label>Комментарий</label>
                                        <input
                                        type='text'
                                        className='form-control'
                                        autoComplete='false'
                                        placeholder='Комментарий'
                                        maxLength={30}
                                        defaultValue={this.props.edit.comment}
                                        onChange={this.setCity('comment')}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group row">
                                        <label className="col-12 col-form-label">{'Работает как кафе (можно посидеть)'}</label>
                                        <div className="col-12">
                                            <Switch onChange={this.setCity('cafe')} checked={this.props.edit.cafe} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group row">
                                        <label className="col-12 col-form-label">Есть действующая акция</label>
                                        <div className="col-12">
                                            <Switch onChange={this.setCity('promotion')} checked={this.props.edit.promotion} />
                                        </div>
                                    </div>
                                </div>
                                {this.props.edit.promotion && <div className="col-md-4">
                                    <div className='form-group'>
                                        <label>Описание акции</label>
                                        <input
                                        type='text'
                                        className='form-control'
                                        autoComplete='false'
                                        placeholder='Описание акции'
                                        defaultValue={this.props.edit.promotionDescription}
                                        onChange={this.setCity('promotionDescription')}
                                        />
                                    </div>
                                </div>}
                            </div>

                            {[roleId.admin].includes(this.props.role.id) && 
                            <div className="row">                                    
                                <div className="col-md-4">
                                    <div className="form-group row">
                                        <div className="col-12">
                                            <button
                                                disabled={this.props.loading}
                                                onClick={(e) => this.handleRemoveUserPoints(e)}
                                                className={cn({ 'btn btn-brand  kt-spinner--right kt-spinner--sm kt-spinner--light': true, 'kt-spinner': this.props.loadingRemoveUserPoint })}>
                                                Сброс привязки пользователей
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                                    
                            {[roleId.admin].includes(this.props.role.id) &&
                            <div className="row">
                                <div className="col-md-12">
                                    <Input
                                        required={true}
                                        label="Координаты"
                                        onChange={this.setCity('coord')}
                                        value={this.props.edit.coord}
                                        placeholder="Координаты..."
                                        disabled={true}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <YMaps
                                        style={{ padding: '25px ', marginTop: -30 }}
                                        query={{
                                            apikey: '71140458-f614-4101-ad58-f75c79f0f6fe'
                                        }}
                                        >
                                        <Map
                                            
                                            width="100%"
                                            defaultState={{ center: this.props.edit.coord.split(','), zoom: 15, controls: [] }}
                                        >
                                             <Placemark geometry={this.props.edit.coord.split(',')} />
                                            <SearchControl instanceRef={ref => this.map = ref} onResultShow={this.onResultShow} options={{ float: 'right' }}/>
                                        </Map>
                                    </YMaps>
                                </div>
                            </div>
                            }
                        </div>
                        <ErrorContainer field="editFilial" style={{ margin: '0 20px 20px' }} hasCloseButton={true}/>
                        <div className="kt-portlet__foot">
                            <div className="kt-form__actions">
                                <button
                                    disabled={!this.isValidFilial() || this.props.loading}
                                    type="submit"
                                    className={cn({ 'btn btn-brand  kt-spinner--right kt-spinner--sm kt-spinner--light': true, 'kt-spinner': this.props.editFilialLoader })}>
                                    Сохранить
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const goodsSelector = state => state.good.list;
    const categoriesSelector = state => state.good.categories;

    const normalizeGoodsSelector = createSelector(
        goodsSelector,
        categoriesSelector,
        (goods, categories) => {
            const resultCategories = categories.reduce((data, item) => {
                data[item.id] = item.title;
                return data;
            }, {});

            const resultGoods = goods.reduce((data, item) => {
                if (data[item.category.id]) {
                    data[item.category.id].push({ value: item.id, label: item.name });
                } else {
                    data[item.category.id] = [{ value: item.id, label: item.name }];
                }
                return data;
            }, {});
            
            const result = Object.keys(resultGoods).map(key => {
                return ({
                    label: resultCategories[key],
                    options: resultGoods[key],
                });
            }).filter(item => item.label);

            return result;
        }
    );

    return ({
        edit: state.handbook.filial.edit,
        detail: state.handbook.filial.detail,
        loading: state.loaders.filial,
        editFilialLoader: state.loaders.editFilial,
        cities: state.handbook.cities,
        kassaAccounts: state.yookassa.list,
        loadingGoods: state.loaders.goods || state.loaders.category,
        loadingRemoveUserPoint: state.loaders.removeUserPoint,
        goods: normalizeGoodsSelector(state),
        role: state.user.info.role
    });
}

const mapDispatchToProps = {
    fetchFilial: (id) => fetchFilial(id),
    setFilialEdit: (data) => setFilialEdit(data),
    fetchCities: () => fetchCities(true),
    fetchKassaAccounts: () => fetchKassaAccounts(true),
    updateFilial: (id, data) => updateFilial(id, data),
    fetchGoods: () => fetchGoods(),
    fetchCategories: () => fetchCategories(),
    emoveUserPoint: (id) => removeUserPoint(id),
    setUIvisible: (visibleFiltersButton, visibleActionButton) => setUIvisible(visibleFiltersButton, visibleActionButton)
}

export default connect(mapStateToProps, mapDispatchToProps)(EditFilial);