import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { fetchUser, setAddForm } from '../../action/user';
import cn from 'classnames';
import request from '../../action/utils';
import { roleId, urls } from '../../constants/app';
import { registerUser, updateUser } from '../../action/user';
import { fetchCities, fetchFilials } from '../../action/handbook';
import Input from '../utils/Input'
import ErrorContainer from '../utils/ErrorContainer';
import Select from 'react-select';
import Loader from '../utils/Loader';
import { setUIvisible } from '../../action/UImobile';

const emailRegExp = /^[-a-z0-9!#$%&'*+/=?^_`{|}~]+(\.[-a-z0-9!#$%&'*+/=?^_`{|}~]+)*@([a-z0-9]([-a-z0-9]{0,61}[a-z0-9])?\.)*(aero|arpa|asia|biz|cat|com|coop|edu|gov|info|int|jobs|mil|mobi|museum|name|net|org|pro|tel|travel|[a-z][a-z])$/i;

const tabs = [
    { name: 'accaunt', title: 'Аккаунт', subTitle: 'Настройка детальной информации', icon: 'flaticon-globe' },
    { name: 'filial', title: 'Филиалы', subTitle: 'Настройка филиалов', icon: 'flaticon-responsive' },
    { name: 'done', title: 'Итоговые данные', subTitle: 'Проверка и сохранение', icon: 'flaticon-confetti' }
];

const styles = {
    loading: {
        minHeight: '500px',
        display: 'flex',
        justifyContent: 'center'
    }
}

class AddUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 0,
            errors: {},
            loader: false,
            loaderUser: false,
            editUserCheck: false,
            editUserId: null,
            checkEmail: null,
            checkLogin: null,
            searchInputText: '',
            foundPoints: [],
            showPasswordInput: false,
            showPasswordDoneStep: false,
        };
    }

    componentDidMount() {
        this.props.setUIvisible(false, false)
        const setData = async () => {
            this.setState({ loaderUser: true, editUserCheck: true, editUserId: this.props.match.params.id });
            return request({ method: 'get', url: `${urls.users}/${this.props.match.params.id}` })
            .then((response) => {
                const { addForm, setAddForm } = this.props;
                const data = { ...addForm };
                this.setState({ checkEmail: response.email, checkLogin: response.username });
                data.accaunt = {
                    login: response.username,
                    name: response.name,
                    phone: response.phone,
                    email: response.email,
                    password: response.password,
                    role: {
                        value: response.role.id,
                        label: response.role.description
                    },
                };
                data.allPoints = response.allPoints;
                if (!response.allPoints) {
                    response.points.map((point, index) => {
                        data.filial[index] = point.id;
                        return (data.filial[index])
                    });
                }
                setAddForm(data);
                return response;
            })
            .finally(() => {
                this.setState({ loaderUser: false });
            });
        }

        if(window.location.pathname.includes('/edit'))
            setData();

        if(!this.props.cities)
            this.props.fetchCities();
    }

    componentWillUnmount() {
        this.props.setAddForm({ accaunt: {}, filial: [] });
    }

    renderSubHeader() {
        return (
            <div className="kt-subheader not-mt kt-grid__item">
                <div className="kt-container  kt-container--fluid">
                <div className="kt-subheader__main">
                    <h3 className="kt-subheader__title" style={window.innerWidth < 400 ? {fontSize: '0.9rem'} : {}}>{`${this.state.editUserCheck ? 'Редактирование пользователя' : 'Создание пользователя'}`}</h3>
                    <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                </div>
                <div className="kt-subheader__toolbar">
                    <button onClick={() => this.props.history.push('/users')} className="btn btn-default btn-bold">
                        Назад
                    </button>
                </div>
            </div>
            </div>
        );
    }

    handleShowPasswordInput = () => {
        this.setState({ showPasswordInput: !this.state.showPasswordInput });
    }

    handleShowPasswordDoneStep = () => {
        this.setState({ showPasswordDoneStep: !this.state.showPasswordDoneStep });
    }

    handleClickCopy = (text) => () => {
        navigator.clipboard.writeText(text);
    }
    
    randomPassword = () => {
        const { addForm, setAddForm } = this.props;
        if (this.state.errors.login || this.state.errors.email) {
            this.setState({ errors: {} });
        }
        const data = { ...addForm };
        data.accaunt = data.accaunt ? data.accaunt : {};            
        const randomPass = Math.random().toString(36).slice(2);
        data.accaunt.password = randomPass;
        document.getElementById('password_input').value = randomPass;
        setAddForm(data);
    }

    onTab = (step) => (e) => {
        e.preventDefault();

        if (this.state.step === 0) {
            this.setState({ loader: true });
            request({method: 'get', url: `${urls.users}/?username=${this.props.addForm.accaunt.login}`}).then(result => {
                if (result.length === 0 || this.props.addForm.accaunt.login === this.state.checkLogin) {                    
                    request({method: 'get', url: `${urls.users}/?email=${this.props.addForm.accaunt.email}`}).then(result => {
                        if (result.length === 0 || this.props.addForm.accaunt.email === this.state.checkEmail) {
                            this.props.fetchFilials();
                            this.setState({ step: step, loader: false });
                        } else {
                            this.setState({ errors: { email: 'Пользователь с таким Email существует' }, loader: false })
                        }
                    });
                } else {
                    this.setState({ errors: { login: 'Пользователь с таким логином существует' }, loader: false })
                }
            })        
        } else {
            this.setState({ step: step });
        }
    }

    setFormValue = (section, field) => (e) => {
        const { addForm, setAddForm } = this.props;
        if (this.state.errors.login || this.state.errors.email) {
            this.setState({ errors: {} });
        }
        const data = { ...addForm };
        data[section] = data[section] ? data[section] : {};
        if(field !== 'role')
            data[section][field] = e.target.value;

        if(field === 'role')
            data[section][field] = e;

        setAddForm(data);
    }

    checkSelectAllFilials() {
        const { addForm } = this.props;
        const checkbox = document.getElementById('checkbox_all_point')
        if(checkbox)
            checkbox.indeterminate = false;

        if(addForm.allPoints || (addForm.filial.length === this.state.foundPoints.length && this.state.foundPoints.length > 0))
            return 1;

        if(addForm.filial.length > 0) {
            if(checkbox)
                checkbox.indeterminate = true;
            return -1;
        }

        return 0;
    }

    setFormFilial = (value) => () => {       
        const { addForm, setAddForm, filials } = this.props;

        const data = { ...addForm };

        if(!data.filial.find((el) => el === value) && !data.allPoints) {
            data.filial = [...data.filial, value]
        } else {
            if(data.allPoints) {
                data.allPoints = false
                data.filial = filials.filter((el) => el.id !== value).map((el) => el.id);
            } else {
                data.filial = data.filial.filter((el) => el !== value);
            }
        }

        setAddForm(data);
    }

    setFormFilialsAll = () => () => {
        const { addForm, setAddForm } = this.props;

        const data = { ...addForm };
        if(this.checkSelectAllFilials() === 0) {
            data.allPoints = true;
            data.filial = [];
        } else {
            data.allPoints = false;
            data.filial = [];
        }
        
        setAddForm(data);
    }
    
    isValidLogin(login = "") {
        if (login && !/^.{4,}$/.test(login)) {
            return 'Минимум 4 символов';
        }
        if (this.state.errors.login) {
            return this.state.errors.login;
        }
        return null;
    }

    isValidPass(pass = "") {
        if((!pass && !this.state.editUserCheck))
            return null 

        if ((pass && !/^.{8,}$/.test(pass))) {
            return 'Минимум 8 символов';
        }
        return null;
    }

    isValidEmail(email = "") {
        if (email && !emailRegExp.test(email)) {
            return 'Некорректный Email';
        }
        if (this.state.errors.email) {
            return this.state.errors.email;
        }
        return null;
    }

    changeSearchInput(value){
        const { filials } = this.props;

        if(value && value !== '') {
            const filteringPoints = filials.filter((item) => this.renderAdress(item).trim().toLowerCase().replace(/[,\s.]/gi, '').includes(value.trim().toLowerCase().replace(/[,\s.]/gi, ''))).sort((a, b) => {
                const adressA = this.renderAdress(a);
                const adressB = this.renderAdress(b);
                if (adressA < adressB) return -1;
                if (adressA > adressB) return 1;
                return 0;
            });
            
            this.setState({foundPoints: filteringPoints, searchInputText: value});
        } else {
            this.resetFoundPoints(true)();
        }
    }

    resetFoundPoints = (reset) => () => {
        const { filials } = this.props;
        if (!filials || filials.length === 0) {
            this.setState({ foundPoints: [] });
            return;
          }

        if(this.state.searchInputText === '' || reset) {
            this.setState({foundPoints: filials.sort((a, b) => {
                    const adressA = this.renderAdress(a);
                    const adressB = this.renderAdress(b);
                    if (adressA < adressB) return -1;
                    if (adressA > adressB) return 1;
                    return 0;
                }),
                searchInputText: ''
            });
        }
        
        const input = document.querySelector('.point_add_user_search-input');

            if(input && reset)
                input.value = null;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.filials !== this.props.filials) 
          this.resetFoundPoints(false)();        
    }
    
    renderAccaunt() {
        const { addForm: { accaunt: { login, password, phone, email, name, role } } } = this.props;

        return (
            <div className="kt-wizard-v2__content" data-ktwizard-type="step-content" data-ktwizard-state="current">
                <div className="kt-heading kt-heading--md">Введите информацию</div>
                <div className="kt-form__section kt-form__section--first">
                    <div className="kt-wizard-v2__form">
                        <Input
                            label="Логин"
                            required={true}
                            onChange={this.setFormValue('accaunt', 'login')}
                            value={login}
                            placeholder="Введите логин"
                            error={this.isValidLogin(login)}
                        />

                        <div style={{display: 'flex', gap: '5px'}}>
                            <div style={{width: '100%'}}>
                                <Input
                                    id='password_input'
                                    label={`${this.state.editUserCheck ? 'Новый пароль (если нужно изменить)' : "Пароль"}`}
                                    required={`${this.state.editUserCheck ? false : true}`}
                                    onChange={this.setFormValue('accaunt', 'password')}
                                    value={password}
                                    placeholder="Введите пароль"
                                    error={this.isValidPass(password)}
                                    type={this.state.showPasswordInput  ? 'text' : "password"}
                                />
                            </div>
                            <button className={`adduser-button showpassword${this.state.showPasswordInput  ? ' shown' : ''}`} onClick={this.handleShowPasswordInput} title={this.state.showPasswordInput  ? 'Скрыть пароль' : 'Показать пароль'}><i className='flaticon-eye'/></button>
                            <button className='adduser-button' onClick={this.randomPassword}>{window.innerWidth > 330 ? 'Сгенерировать' : 'Сгенер...'}</button>
                        </div>

                        <Input
                            label="ФИО"
                            required={true}
                            onChange={this.setFormValue('accaunt', 'name')}
                            value={name}
                            placeholder="Введите ФИО"
                        />
                      
                        <div className="row">
                            <div className="col-xl-6">
                                <Input
                                    label="Телефон"
                                    onChange={this.setFormValue('accaunt', 'phone')}
                                    value={phone}
                                    placeholder="Введите телефон"
                                />
                            </div>
                            <div className="col-xl-6">
                                <Input
                                    label="Email"
                                    required={true}
                                    onChange={this.setFormValue('accaunt', 'email')}
                                    value={email}
                                    placeholder="Введите email"
                                    error={this.isValidEmail(email)}
                                />
                            </div>
                        </div>

                        <label>{'Роль*'}</label>
                        <div style={window.innerWidth > 1400 ? { maxWidth: '50%', paddingRight: '10px' } : { maxWidth: '100%', paddingRight: '0px' }}>                          
                            <Select
                            value={role}
                            closeMenuOnSelect={true}
                            onChange={this.setFormValue('accaunt', 'role')}
                            styles={{
                                menuPortal: base => ({ ...base, zIndex: 9999 })
                            }}
                            menuPlacement="auto"
                            options={[
                                { value: roleId.operator, label: 'Оператор' },
                                { value: roleId.coordinator, label: 'Координатор' },
                                { value: roleId.cashier, label: 'Кассир' },
                                { value: roleId.manager, label: 'Управляющий' },
                                { value: roleId.commodityExpert, label: 'Товаровед' },
                                { value: roleId.accountant, label: 'Бухгалтер' },
                                { value: roleId.marketingSpecialist, label: 'Маркетолог' },
                                { value: roleId.regionalDirector, label: 'Региональный директор' },
                                { value: roleId.chiefCommoditySpecialist, label: 'Главный товаровед' },
                                { value: roleId.chiefAccountant, label: 'Главный бухгалтер' },
                                { value: roleId.chiefMarketingSpecialist, label: 'Главный маркетолог' },
                            ]}
                            placeholder="Роль"
                            noOptionsMessage={() => 'Роли не найдены'}
                            />
                        </div>
                    </div>
                </div>
            </div>
                        
        );
    }

    renderAdress(item) {
        if(item.city.name) {
            return `${item.city.name}, ${item.street}, ${item.house}`;
        } else {
            const city = this.props.cities.find((city) => city.id === item.city)
            return `${city.name}, ${item.street}, ${item.house}`;
        }
    }


    renderFilial() {
        const { addForm: { filial, allPoints }, filials } = this.props;

        return (
            <div className="kt-wizard-v2__content" data-ktwizard-type="step-content" data-ktwizard-state="current">
                <div className="kt-heading kt-heading--md">Настройка филиалов <label className='kt-heading kt-heading--sm kt-heading--vsm kt-heading--thin kt-shape-font-color-4'>{` Выбрано филиалов: ${filial.length ? filial.length : '0'} из ${filials.length ? filials.length : '0'}`}</label></div>
                <div className="kt-form__section kt-form__section--first">
                    <div className="kt-wizard-v2__form" style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                        {!this.props.loadingFilials && 
                        <>
                            {this.state.foundPoints.length > 0 ?
                                <div
                                    className='point_add_user_checkbox-div'
                                    onClick={this.setFormFilialsAll()}
                                >
                                    <input type='checkbox' id='checkbox_all_point' checked={(this.checkSelectAllFilials() === 1 ? true : this.checkSelectAllFilials() === 0 && false) || allPoints} />
                                    <span>Выбрать все{this.state.foundPoints.length !== filials.length ? ' показанные' : ''}</span>
                                </div>
                                :
                                <div
                                    className='point_add_user_checkbox-div'
                                >
                                    <span>Филиалов не найдено</span>
                                </div>
                            }
                            <div
                            className='point_add_user_checkbox-div'
                            style={{ cursor: 'auto' }}
                            >
                                <input className='point_add_user_search-input' placeholder='Поиск филиалов' value={this.state.searchInputText} onChange={((e) => { this.changeSearchInput(e.target.value) })} />
                                {this.state.foundPoints.length !== filials.length &&
                                    <>
                                        <span className='css-1okebmr-indicatorSeparator' style={{ margin: '0px' }}></span>
                                        <div className='css-tlfecz-indicatorContainer' style={{ padding: '0px', cursor: 'pointer' }} onClick={this.resetFoundPoints(true)}> <i className="flaticon2-cross" /> </div>
                                    </>
                                }
                            </div>
                        </>
                        }
                        {
                            this.state.foundPoints.map((item) => (
                                <div 
                                key={item.id} 
                                className='point_add_user_checkbox-div'
                                onClick={this.setFormFilial(item.id)}
                                >
                                    <input type='checkbox' checked={(filial.find((fil) => fil === item.id) ? true : false) || allPoints}/>
                                    {this.renderAdress(item)}
                                </div>
                            ))
                        }

                    </div>
                </div>
            </div>        
        );
    }

    renderDone() {
        const {
            addForm: {
                accaunt: { login, phone, email, name, role, password  },
                filial, allPoints
            },
            filials
        } = this.props;

        return (
            <div className="kt-wizard-v2__content" data-ktwizard-type="step-content" data-ktwizard-state="current">
                <div className="kt-heading kt-heading--md">Проверка и сохранение</div>
                <div className="kt-form__section kt-form__section--first">
                    <div className="kt-wizard-v2__review">
                        <div className="kt-wizard-v2__review-item">
                            <div className="kt-wizard-v2__review-title">
                                Аккаунт
                            </div>
                            <div className="kt-wizard-v2__review-content">
                            Логин: {login} 
                                {login && password && 
                                <button 
                                className='adduser-detail-button'
                                onClick={this.handleClickCopy('Логин: ' + login + '\nПароль: ' + password)}
                                >
                                    {window.innerWidth > 360 ? 'Копировать логин и пароль' : 'Копировать'}
                                </button>
                                }
                                <br/>
                                {password && !this.state.showPasswordDoneStep && 
                                <>
                                <button 
                                    className='adduser-detail-button showpassword'
                                    onClick={this.handleShowPasswordDoneStep}
                                >
                                    Показать пароль
                                </button>
                                <br/>
                                </>
                                }
                                {password && this.state.showPasswordDoneStep && 
                                <>
                                    Пароль: {password}<br/>
                                </>
                                }
                                ФИО: {name}<br/>
                                {phone && (<>Телефон: {phone}<br/></>)}
                                Email: {email}<br/>
                                Роль: {role.label}
                            </div>
                        </div>
                        <div className="kt-wizard-v2__review-item">
                            <div className="kt-wizard-v2__review-title">
                                Филиалы
                            </div>
                            <div className="kt-wizard-v2__review-content">
                                {
                                    filial.length === filials.length || allPoints
                                    ?
                                    'Все филиалы'
                                    :
                                    filial.filter(fil => fil).map(fil => {
                                        return <div>{this.renderAdress(filials.filter(item => item.id === +fil)[0])}</div>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <ErrorContainer field="register" style={{ margin: '30px 0 0' }} hasCloseButton={true}/>
            </div>
        
        );
    }

    renderTabContent() {
        switch(this.state.step) {
            case 0: return this.renderAccaunt();
            case 1: return this.renderFilial();
            case 2: return this.renderDone();
            default: return null;
        }
    }

    onBack = (e) => {
        e.preventDefault();
        this.setState({ step: this.state.step - 1 });   
    }

    onNext = (e) => {
        e.preventDefault();

        if (this.state.step === 0) {
            this.setState({ loader: true });
            request({method: 'get', url: `${urls.users}/?username=${this.props.addForm.accaunt.login}`}).then(result => {
                if (result.length === 0 || this.props.addForm.accaunt.login === this.state.checkLogin) {
                    request({method: 'get', url: `${urls.users}/?email=${this.props.addForm.accaunt.email}`}).then(result => {
                        if (result.length === 0 || this.props.addForm.accaunt.email === this.state.checkEmail) {
                                this.props.fetchFilials();
                                this.setState({ step: this.state.step + 1, loader: false });                         
                        } else {
                            this.setState({ errors: { email: 'Пользователь с таким Email существует' }, loader: false })
                        }
                    });
                } else {
                    this.setState({ errors: { login: 'Пользователь с таким логином существует' }, loader: false })
                }
            })
        } else {
            this.setState({ step: this.state.step + 1 });
        }
    }

    onSave = (e) => {
        e.preventDefault();
        const { addForm, registerUser, updateUser, filials  } = this.props;
        const { accaunt, filial, allPoints } = addForm;
        const user = {};
        user.username = accaunt.login;
        user.name = accaunt.name;        
        user.email = accaunt.email;
        user.role = accaunt.role.value
        user.confirmed = 1;

        if(!this.state.editUserCheck || (accaunt.password && this.state.editUserCheck))
            user.password = accaunt.password;

        if (accaunt.phone) {
            user.phone = accaunt.phone;
        }

        if(filial && filial.length !== filials.length) {
            user.points = filial;
            user.allPoints = false;
        }

        if((filial && filial.length === filials.length) || allPoints) {
            user.points = null;
            user.allPoints = true;
        }
        
        if(this.state.editUserCheck) {
            updateUser(this.state.editUserId, user).then(result => {
                if (result) {
                    this.props.history.push('/users');
                }
            });
        } else {
            registerUser(user).then(result => {
                if (result) {
                    this.props.history.push('/users');
                }
            });
        }       
    }

    isValidAccaunt() {
        const { addForm: { accaunt: { login, password, email, name, role } } } = this.props;
        if (!login || (!password && !this.state.editUserCheck) || !email || !name || !role) {          
            return false;
        }

        if (this.isValidLogin(login) || this.isValidPass(password) || this.isValidEmail(email)) {
            return false;
        }
        return true;
    }

    isValidFilial() {
        if (this.props.addForm.filial.length === 0 && !this.props.addForm.allPoints) {
            return false
        }
        return true;
    }

    isValidNext() {
        switch(this.state.step) {
            case 0: return this.isValidAccaunt();
            case 1: return this.isValidFilial();
            default: return true;
        }
    }

    isValidMenuButton(step) {
        switch(step) {
            case 0: return true;
            case 1: return this.isValidAccaunt();
            case 2: return this.isValidAccaunt() && this.isValidFilial();
            default: return true;
        }
    }

    renderLoader() {
        return (
            <div data-ktwizard-type="action-next">
                <div style={{ marginRight: '25px' }} className="kt-spinner kt-spinner--lg kt-spinner--info"></div>
            </div>
        );
    }

    renderAction() {
        return (
            <div className="kt-form__actions">
                <button onClick={this.onBack} className="btn btn-secondary btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" data-ktwizard-type="action-prev">
                    Назад
                </button>
                {
                    this.props.loading ? this.renderLoader() : (
                        <button onClick={this.onSave} className="btn btn-success btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" data-ktwizard-type="action-submit">
                            Сохранить
                        </button>
                    )
                }
                
                {
                    (this.state.loader || this.props.loadingFilials) ? this.renderLoader() : (
                        <button disabled={!this.isValidNext()} onClick={this.onNext} className="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u" data-ktwizard-type="action-next">
                            Далее
                        </button>
                    )
                }
                
            </div>
        );
    }

    preventDefault = (e) => {
        e.preventDefault();
    }

    renderTabs() {
        return (
            <div
            className="kt-grid  kt-wizard-v2 kt-wizard-v2--white" data-ktwizard-state={this.state.step === 0 ? 'first' : this.state.step === 2 ? 'last' : 'between'}>
                {window.innerWidth > 880 && <div className="kt-grid__item kt-wizard-v2__aside">
                    <div className="kt-wizard-v2__nav">
                        <div className="kt-wizard-v2__nav-items">
                            {
                                tabs.map((tab, index) => 
                                (
                                    <a
                                        key={index}
                                        onClick={this.isValidMenuButton(index) ? this.onTab(index) : this.preventDefault}
                                        className={cn({ 'kt-wizard-v2__nav-item': true, disabled: !this.isValidMenuButton(index) })}
                                        href="/"
                                        data-ktwizard-type="step"
                                        data-ktwizard-state={this.state.step === index ? 'current' : 'pending'}>
                                        <div className="kt-wizard-v2__nav-body">
                                            <div className="kt-wizard-v2__nav-icon">
                                                <i className={tab.icon}></i>
                                            </div>
                                            <div className="kt-wizard-v2__nav-label">
                                                <div className="kt-wizard-v2__nav-label-title">
                                                    {tab.title}
                                                </div>
                                                <div className="kt-wizard-v2__nav-label-desc">
                                                    {tab.subTitle}
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                ))
                            }
                        </div>
                    </div>
                </div>}
                <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper">
                    <form onSubmit={(e) => e.preventDefault()} className="kt-form" noValidate="noValidate">
                        {this.renderTabContent(this.state.step)} 
                        {this.renderAction()}
                    </form>
                </div>
            </div>
    
        );
    }

    render() {
        if(this.state.loaderUser)
            return <div style={styles.loading}><Loader /></div>

        return (
            <Fragment>
                {this.renderSubHeader()}
                <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                <div className="kt-portlet">
                    <div className="kt-portlet__body kt-portlet__body--fit">
                       {this.renderTabs()}
                    </div>
                </div>
	        </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    addForm: state.users.add || {},
    loading: state.loaders.register || state.loaders.updateUser,
    loadingFilials: state.loaders.filials,
    cities: state.handbook.cities,
    filials: state.handbook.filials,
});

const mapDispatchToProps = {
    setAddForm: (data) => setAddForm(data),
    registerUser: (user) => registerUser(user),
    updateUser: (id, user) => updateUser(id, user),
    fetchFilials: () => fetchFilials(null, true),
    fetchCities: () => fetchCities(true),
    fetchUser: (id) => fetchUser(id),
    setUIvisible: (visibleFiltersButton, visibleActionButton) => setUIvisible(visibleFiltersButton, visibleActionButton)
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);