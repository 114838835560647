import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { updateGood } from '../../../action';
import { fromStore } from '../../../selectors';
import { getImageUrl } from '../../../utils/common';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

export function DetailCard() {
    const dispatch = useDispatch();
    const detail = useSelector(fromStore.detailGoodSelector);
    const loaderUpdateGood = useSelector(fromStore.loaderUpdateGoodSelector);
    const loaderDeleteGood = useSelector(fromStore.loaderDeleteGoodSelector);

    const onToogleActiveGood = () => {
        const data = { active: !detail.active };
        dispatch(updateGood(detail.id, data))
    };

    return (
        <div className="kt-portlet">
            <div className="kt-portlet__body">
                <div className="kt-widget kt-widget--user-profile-3">
                    <div className="kt-widget__top">
                        <div className="kt-widget__media">
                            <img src={getImageUrl(detail.image)} alt={detail.title} />
                        </div>
                        <div className="kt-widget__content">
                            <div className="kt-widget__head">
                                <div className="kt-widget__user">
                                    <div className="kt-widget__username" style={{ marginRight: '10px' }}>
                                       {detail.name}
                                    </div>
                                    {detail.active
                                        ? <span className="kt-badge kt-badge--success kt-badge--inline">Включен</span>
                                        : <span className="kt-badge kt-badge--danger kt-badge--inline">Выключен</span>
                                    }
                                </div>
                                <div className="kt-widget__action">
                                    <button
                                        onClick={onToogleActiveGood}
                                        data-tip={detail.active ? 'Выключить' : 'Включить'}
                                        disabled={loaderUpdateGood || loaderDeleteGood}
                                        className={cn("btn btn-sm btn-icon btn-icon-md", {
                                            'btn-danger': !detail.active,
                                            'btn-success': detail.active,
                                            'kt-spinner kt-spinner--center kt-spinner--sm kt-spinner--light': loaderUpdateGood
                                        })}>
                                        {!loaderUpdateGood && <i className="la la-power-off"></i>}
                                    </button>
                                    
                                </div>
                            </div>
                            <div className="kt-widget__subhead detail_good">
                                <div className='sub_widget detail_good'>
                                    <span>ID: {detail.id}</span>
                                    <span>ID категории: {detail.category.id}</span>
                                    <span>FRONTPAD ID: {detail.article}</span>
                                </div>
                                <span>Создан: {moment(detail.created_at).format('DD.MM.YYYY в HH:mm')}</span>
                                <span>Обновлен: {moment(detail.updated_at).format('DD.MM.YYYY в HH:mm')}</span>
                            </div>
                            <div className="kt-widget__info">
                                <div className="kt-widget__desc">
                                    {detail.description}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ReactTooltip
                effect="solid"
                type="dark"
                place="top"
            />
        </div>
    );
}