import React from 'react';

export const TableHeader = () => (
    <thead className="kt-datatable__head">
        <tr className="kt-datatable__row">
            <th className="kt-datatable__cell" style={{ width: '150px' }}><span>IP</span></th>
            <th className="kt-datatable__cell" style={{ width: '150px' }}><span>Дата блокировки</span></th>
            <th className="kt-datatable__cell" style={{ width: 'auto' }}><span>Инициатор</span></th>
            <th style={{ textAlign: 'right' }} width="120px" className="kt-datatable__cell"><span>Действия</span></th>
        </tr>
    </thead>
);