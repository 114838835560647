import React, { Component } from 'react';
import ActionMenu from '../utils/ActionMenu';
import { withRouter  } from 'react-router-dom';
import Select from 'react-select';
import { timeDelivery, optionsMinDeliveryTime, optionsMinSamTime, roleId } from '../../constants/app'
import _ from 'lodash';
import SelectStopList from './SelectStopList';

const activateSelect = {
    'active': 'Активирован',
    'disabledAll': 'Полностью остановлен',
    'disabledDelivery': 'Доставка остановлена',
    'disabledOwn': 'Самовывоз остановлен',
};

class TableFilials extends Component {
    setFilial = (field, id) => (e) => {
        const filial = this.props.items.filter((filial) => {
            if(filial.id === id) {
                return true
            } else {
                return false
            }
        }).shift()
        
        if (field === 'minDeliveryTime' || field === 'minSamTime') {
            filial[field] = e.value;
        }

        if (field === 'stopList' || field === 'stop' || field === 'directOrder') {
            filial[field] = e;
        } else if (field !== 'minDeliveryTime' && field !== 'minSamTime') {
            filial[field] = e.target.value;
        }
            
        if(_.includes(this.props.editFilials, filial)){
            this.props.changeFilialsEdit(filial)
        } else {
            this.props.addFilialsEdit(filial)
        }
    }

    isValidFilial = (id) => {
        const filial = this.props.editFilials.filter((filial) => {
            if(filial.id === id) {
                return true
            } else {
                return false
            }
        }).shift()

        if(filial) {
            return false;
        } else {
            return true;
        }
    }

    saveFilial = (e, id) => {
        const dat = this.props.editFilials.filter(editFilial => {
            if (editFilial.id === id) {
                return true
            } else {
                return false
            }
        }).shift();

        delete dat.users;

        this.props.updateFilial(id, dat).then(result => {
            if (result) {
                this.props.fetchFilials();
            }
        });

        this.props.removeFilialsEdit(dat)
    }

    resetTime = (e, id) => {
        this.props.fetchFilial(id).then(result => {
            this.props.setFilialEdit({...result, minDeliveryTime: timeDelivery.minDeliveryTime, minSamTime: timeDelivery.minSamTime});
            const dat = { ...this.props.filial.edit };
            delete dat.users;
            this.props.updateFilial(id, dat).then(result => {
                    if (result) {
                        this.props.fetchFilials();
                    }
                });
        });
    }

    renderThead() {
        return (
            <thead className="kt-datatable__head">
                <tr className="kt-datatable__row">
                    <th className="kt-datatable__cell table_filials_point">
                        <span>Филиал</span>
                    </th>
                    <th className="kt-datatable__cell table_filials_delivery_time">
                        <span>Мин. время доставки</span>
                    </th>
                    <th className="kt-datatable__cell table_filials_delivery_time">
                        <span>Мин. время самовывоза</span>
                    </th>
                    <th className="kt-datatable__cell table_filials_point_status">
                        <span>Состояние</span>
                    </th>
                    <th className="kt-datatable__cell table_filials_stop_list">
                        <span>Стоп лист товаров</span>
                    </th>
                    <th className="kt-datatable__cell table_filials_buttons">
                        <span></span>
                    </th>
                    <th className="kt-datatable__cell table_filials_action">
                        <span>Действия</span>
                    </th>
                </tr>
            </thead>
        );
    }
    
    handleDoubleClick = (item) => () => {
        this.props.history.push(`/handbook/filials/edit/${+item.id}`)
    }

    renderAdress(item) {
        return `${item.street}, ${item.house}${item.entrance ? `, подъезд ${item.entrance}` : ''}${item.room ? `, кв./оф. ${item.room}` : ''}`;
    }
    
    renderBody() {
        
        return (
            <tbody className="kt-datatable__body">
                {
                    this.props.items.map(item => {
                        const actions = [
                            //{ access: roleId.admin, name: item.stop ? 'Включить' : 'Выключить', icon: 'flaticon2-contrast', action: () => this.props.activate(item) },
                            { name: 'Просмотр', icon: 'flaticon2-expand', action: () => this.props.history.push(`/handbook/filials/${+item.id}`) },
                            { access: [roleId.admin, roleId.coordinator, roleId.cashier, roleId.manager, roleId.regionalDirector], name: 'Редактирование', icon: 'flaticon2-contract', action: () => this.props.history.push(`/handbook/filials/edit/${+item.id}`) },
                            { access: [roleId.admin], name: 'Удалить', icon: 'flaticon2-trash', action: () => this.props.delete(item) }
                        ].filter(item => !item.access || item.access.includes(this.props.role));
                        return (
                            <tr onDoubleClick={this.handleDoubleClick(item)} key={item.id} className="kt-datatable__row">
                            <td className="kt-datatable__cell table_filials_point">
                                <span>
                                    { item.city.name + ', ' + item.street }
                                </span>
                            </td>
                            <td className="kt-datatable__cell table_filials_delivery_time">
                                <div onDoubleClick={((event) => {event.stopPropagation()})}>
                                    <Select
                                        isDisabled={item.activate === 'disabledAll' || item.activate === 'disabledDelivery' || item.hiddenClient}
                                        menuPortalTarget={document.body}
                                        closeMenuOnSelect={true}
                                        options={optionsMinDeliveryTime}
                                        onChange={this.setFilial('minDeliveryTime', item.id)}
                                        value={{value: item.minDeliveryTime, label: item.minDeliveryTime}}
                                    />
                                </div>
                            </td>
                            <td className="kt-datatable__cell table_filials_delivery_time">
                                <span onDoubleClick={((event) => {event.stopPropagation()})}>
                                    <Select
                                        isDisabled={item.activate === 'disabledAll' || item.activate === 'disabledOwn' || item.hiddenClient}
                                        menuPortalTarget={document.body}
                                        closeMenuOnSelect={true}
                                        options={optionsMinSamTime}
                                        onChange={this.setFilial('minSamTime', item.id)}
                                        value={{value: item.minSamTime, label: item.minSamTime}}
                                    />
                                </span>
                            </td>
                            <td className="kt-datatable__cell table_filials_point_status" style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                                <span style={{ width: '150px' }} onDoubleClick={((event) => {event.stopPropagation()})}>
                                    <select menuPlacement='auto' disabled={item.hiddenClient} onChange={(e) => this.setFilial('activate', item.id)(e) } value={item.activate} className="form-control"
                                    >
                                        {
                                            Object.keys(activateSelect).map((key, index) => (
                                                <option key={key} value={key}>{activateSelect[key]}</option>
                                            ))
                                        }
                                    </select>
                                </span>

                                <div className={`point_status_circle ${item.activate}`}></div>
                            </td>

                            <td className="kt-datatable__cell table_filials_stop_list">
                                    <span onDoubleClick={((event) => {event.stopPropagation()})}>
                                        <SelectStopList 
                                            isLoading={this.props.loadingGoods}
                                            isDisabled={this.props.loadingGoods}
                                            goods={this.props.goods}
                                            stopList={item.stopList}
                                            setFilial={this.setFilial}
                                            id={item.id}
                                        />
                                    </span>
                                </td>
                            <td className="kt-datatable__cell table_filials_buttons">
                                <span style={{ display: 'flex', gap: '10px', justifyContent: 'flex-end' }}>
                                     <button
                                        title="Сбросить время"
                                        type="button"
                                        onClick={(e) => {
                                            if(!item.hiddenClient)
                                                this.resetTime(e, item.id);
                                        }}
                                        onDoubleClick={((event) => {event.stopPropagation()})}
                                        disabled={(item.minDeliveryTime === timeDelivery.minDeliveryTime && item.minSamTime === timeDelivery.minSamTime) || item.hiddenClient ? true : false}
                                        className="btn btn-outline-secondary btn-sm btn-icon "
                                        >
                                            <i className="flaticon-reply"></i>
                                    </button>
                                    <button
                                        title="Сохранить изменения"
                                        type="button"
                                        onClick={(e) => {
                                            this.saveFilial(e, item.id)
                                        }}
                                        onDoubleClick={((event) => {event.stopPropagation()})}
                                        disabled={this.isValidFilial(item.id) || this.props.loading}
                                        className="btn btn-outline-secondary btn-sm btn-icon "
                                        >
                                            <i className="flaticon2-check-mark"></i>
                                        
                                    </button>
                                </span>
                            </td>
                            <td className="kt-datatable__cell table_filials_action" onDoubleClick={((event) => {event.stopPropagation()})}>
                                <ActionMenu actions={actions}/>
                            </td>
                        </tr>
                        )
                    }) 
                }
            </tbody>
        );
    }

    renderBodyMobile(){
        return(
            <tbody className="kt-datatable__body">
                {
                    this.props.items.map(item => {
                        const actions = [
                            //{ access: roleId,admin, name: item.stop ? 'Включить' : 'Выключить', icon: 'flaticon2-contrast', action: () => this.props.activate(item) },
                            { name: 'Просмотр', icon: 'flaticon2-expand', action: () => this.props.history.push(`/handbook/filials/${+item.id}`) },
                            { access: [roleId.admin, roleId.coordinator, roleId.cashier, roleId.manager, roleId.regionalDirector], name: 'Редактирование', icon: 'flaticon2-contract', action: () => this.props.history.push(`/handbook/filials/edit/${+item.id}`) },
                            { access: [roleId.admin], name: 'Удалить', icon: 'flaticon2-trash', action: () => this.props.delete(item) }
                        ].filter(item => !item.access || item.access.includes(this.props.role));
                        return(
                            <tr onDoubleClick={this.handleDoubleClick(item)} key={item.id} className="kt-datatable__row">
                                <div className='mobile_div_flex column padding10px'>
                                    <div className='mobile_div_flex column_mobile760px' style={{ width: '100%' }}>
                                        <div className='mobile_div_flex column gap5px' style={{  width: '330px'}}>
                                            <span style={{ color: '#000' }}>
                                                { item.city.name + ', ' + item.street }
                                            </span>
                                            <span className='mobile_div_flex' style={{ alignItems: 'center' }}>
                                                <span style={ window.innerWidth > 350 ? { width: 'calc(100% - 30px)' } : { width: 'calc(100% - 60px)' }} onDoubleClick={((event) => {event.stopPropagation()})}>
                                                    <select menuPlacement='auto' disabled={item.hiddenClient} onChange={(e) => this.setFilial('activate', item.id)(e) } value={item.activate} className="form-control"
                                                    >
                                                        {
                                                            Object.keys(activateSelect).map((key, index) => (
                                                                <option key={key} value={key}>{activateSelect[key]}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </span>
                                                <div className={`point_status_circle ${item.activate}`}></div>
                                            </span>
                                        </div>
                                        <div className='mobile_div_flex'>
                                            <div className='mobile_div_flex column gap5px' style={ window.innerWidth > 760 ? { width: '120px' } : { width: '145px' }} onDoubleClick={((event) => {event.stopPropagation()})}>
                                                <span>
                                                    Доставка
                                                </span>
                                                <span>
                                                <Select
                                                    isDisabled={item.activate === 'disabledAll' || item.activate === 'disabledDelivery' || item.hiddenClient}
                                                    menuPortalTarget={document.body}
                                                    closeMenuOnSelect={true}
                                                    menuPlacement="auto"
                                                    styles={{
                                                        menuPortal: base => ({ ...base, zIndex: 9999 })
                                                    }}
                                                    options={optionsMinDeliveryTime}
                                                    onChange={this.setFilial('minDeliveryTime', item.id)}                                         
                                                    value={{value: item.minDeliveryTime, label: item.minDeliveryTime}}
                                                />
                                                </span>
                                            </div>
                                            <div className='mobile_div_flex column gap5px' style={ window.innerWidth > 760 ? { width: '120px' } : { width: '145px' }}  onDoubleClick={((event) => {event.stopPropagation()})}>
                                                <span>
                                                    Вынос
                                                </span>
                                                <span>
                                                <Select
                                                    isDisabled={item.activate === 'disabledAll' || item.activate === 'disabledOwn' || item.hiddenClient}
                                                    menuPortalTarget={document.body}
                                                    closeMenuOnSelect={true}
                                                    menuPlacement="auto"
                                                    styles={{
                                                        menuPortal: base => ({ ...base, zIndex: 9999 })
                                                    }}
                                                    options={optionsMinSamTime}
                                                    onChange={this.setFilial('minSamTime', item.id)}
                                                    value={{value: item.minSamTime, label: item.minSamTime}}
                                                />
                                                </span>
                                            </div>
                                        </div>                                        
                                        
                                    </div>
                                    <div className='mobile_div_flex' style={{ width: '100%' }}>
                                        <span style={window.innerWidth > 760 ? { width: '330px' } : { width: '200px' }} onDoubleClick={((event) => {event.stopPropagation()})}>
                                            <SelectStopList 
                                                isLoading={this.props.loadingGoods}
                                                isDisabled={this.props.loadingGoods}
                                                goods={this.props.goods}
                                                stopList={item.stopList}
                                                setFilial={this.setFilial}
                                                id={item.id}
                                            />
                                        </span>                                    
                                        <div className='mobile_div_flex' style={{ alignItems: 'flex-end', paddingBottom: '5px' }} onDoubleClick={((event) => {event.stopPropagation()})}>
                                            <button
                                            title="Сбросить время"
                                            type="button"
                                            onClick={(e) => {
                                                if(!item.hiddenClient)
                                                    this.resetTime(e, item.id)
                                            }}
                                            onDoubleClick={((event) => {event.stopPropagation()})}
                                            disabled={(item.minDeliveryTime === timeDelivery.minDeliveryTime && item.minSamTime === timeDelivery.minSamTime) || item.hiddenClient ? true : false}
                                            className="btn btn-outline-secondary btn-sm btn-icon "
                                            >
                                                <i className="flaticon-reply"></i>
                                            </button>
                                            <button
                                            title="Сохранить изменения"
                                            type="button"
                                            onClick={(e) => {
                                                this.saveFilial(e, item.id)
                                            }}
                                            onDoubleClick={((event) => {event.stopPropagation()})}
                                            disabled={this.isValidFilial(item.id) || this.props.loading}
                                            className="btn btn-outline-secondary btn-sm btn-icon "
                                            >
                                                <i className="flaticon2-check-mark"></i>                                                
                                            </button>
                                        </div>
                                        <div className='mobile_div_flex' style={{ alignItems: 'flex-end', paddingBottom: '5px'}} onDoubleClick={((event) => {event.stopPropagation()})}>
                                            <ActionMenu actions={actions}/>
                                        </div>
                                    </div>                                    
                                </div>
                            </tr>
                        )
                    })
                }                
            </tbody>
        );
    }

    render() {
        if (this.props.items.length === 0) {
            return (
                <div>Филиалов не найдено</div>
            );
        }

        if(window.innerWidth <= 920 || (this.props.UImobile.openSidebar && window.innerWidth <= 1150 && window.innerWidth > 1024))
            return(
                <table className="kt-datatable__table" style={{ minHeight: '500px' }}>
                    {this.renderBodyMobile()}
                </table>
            );

        return (
            <table className="kt-datatable__table" style={{ minHeight: '500px' }}>
                {this.renderThead()}
                {this.renderBody()}
            </table>
        );
    }
}

export default withRouter(TableFilials);