import React from 'react';

export const TableHeader = () => (
    <thead className="kt-datatable__head">
        <tr className="kt-datatable__row">
            <th width="80px" className="kt-datatable__cell"><span>ID</span></th>
            <th width="220px" className="kt-datatable__cell"><span>Превью</span></th>
            <th className="kt-datatable__cell slider_title"><span>Название</span></th>
            <th width="auto" className="kt-datatable__cell"><span>Описание</span></th>
            <th style={{ textAlign: 'right' }} width="180px" className="kt-datatable__cell table_action"><span>Действия</span></th>
        </tr>
    </thead>
);