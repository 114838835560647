import React, { useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import swal from 'sweetalert';
import { fromStore } from '../../selectors';
import { setStatFilters, fetchPointsLogs } from '../../action/statistics';
import { fetchCities } from '../../action/handbook';
import Select from 'react-select';
import DateInput from '../utils/DateInput';
import moment from 'moment';
import { utils, writeFileXLSX } from 'xlsx';

const DownloadLabel = (props) => {
    const { download, label } = props;
    
    return (
        <div className="kt-portlet">
            <div className="kt-portlet__head">
                <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title">
                        {label}
                    </h3>
                </div>
                <div className="kt-portlet__head-toolbar">
                    <div className="dateBlock" style={{ marginRight: 10 }}>
                        <button onClick={download} className="btn btn-dark btn-icon-sm">Экспорт</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

const Points = () => {
    const dispatch = useDispatch();
    const pointslogs = useSelector(fromStore.statisticsPointslogsSelector);
    const cities = useSelector(fromStore.citiesListSelector);
    const loadingCity = useSelector(fromStore.loaderCitiesSelector);
    const { loading, filters } = pointslogs;
    
    useEffect(() => {
        dispatch(fetchCities(true));
    }, [dispatch]);

    const setFilters = (field) => (data) => {
        const updated = {
            ...filters,
            [field]: data
        };
        dispatch(setStatFilters('pointslogs', updated));
    }

    const onChangeDateInput = (field) => (date) => {
        const { filters } = pointslogs;

        const updated = {
            ...filters,
            [field]: date,
        };

        const isAfter = moment(updated.createdStartDate).isAfter(updated.createdEndDate);
        
        if (field === 'createdEndDate' && isAfter) {
            updated.createdStartDate = date;
        }
        
        if (field === 'createdStartDate' && isAfter) {
            updated.createdEndDate = date;
        }

        dispatch(setStatFilters('pointslogs', updated));
    }

    const onDownloadPointsLogs = useCallback( async () => {
        const res = await dispatch(fetchPointsLogs());

        if(!res || res.length === 0) {
            swal({
                title: "Данные отсутствуют",
                text: "Выберите другие фильтры",
                icon: "warning",
                dangerMode: true,
            })
            return;
        }

        if(!!res) {
            const logs = res.map(item => {
                return {
                    'Id города': item.city == null ? '-' : item.city.id == null ? '-' : item.city.id,
                    'Город': item.city == null ? '-' : item.city.name == null ? '-' : item.city.name,
                    'Id филиала': item.point == null ? '-' : item.point.id == null ? '-' : item.point.id,
                    'Филиал': item.point == null ? '-' : item.point.street == null ? '-' : item.point.street,
                    'Поле': item.field,
                    'Значение текущее': item.value,
                    'Значение предыдущее': item.prevValue,
                    'Дата (сервер)': moment.utc(item.created_at).format('DD.MM.YYYY HH:mm'),
                    'Дата (пользователь)': moment(item.created_at).format('DD.MM.YYYY HH:mm'),
                }
            });
            const ws = utils.json_to_sheet(logs);
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "Data");
            writeFileXLSX(wb, "PointsLogs.xlsx");
        }
    }, [dispatch]);

    const onDownloadIntervals = useCallback(async (partUrl, field, fileName) => {
        const res = await dispatch(fetchPointsLogs(partUrl, field));

        if(!res || res.length === 0) {
            swal({
                title: "Данные отсутствуют",
                text: "Выберите другие фильтры",
                icon: "warning",
                dangerMode: true,
            })
            return;
        }

        if(!!res) {
            const logs = res
                .map(group => {
                    const result = group.map(item => {
                        return {
                            'Id города': item.city == null ? '-' : item.city.id == null ? '-' : item.city.id,
                            'Город': item.city == null ? '-' : item.city.name == null ? '-' : item.city.name,
                            'Id филиала': item.point == null ? '-' : item.point.id == null ? '-' : item.point.id,
                            'Филиал': item.point == null ? '-' : item.point.street == null ? '-' : item.point.street,
                            'Начальное значение': item.startValue,
                            'Окончательное значение': item.endValue,
                            'Начало интервала (сервер)': moment.utc(item.start).format('DD.MM.YYYY HH:mm'),
                            'Окончание интервала (сервер)': moment.utc(item.end).format('DD.MM.YYYY HH:mm'),
                            'Начало интервала (пользователь)': moment(item.start).format('DD.MM.YYYY HH:mm'),
                            'Окончание интервала (пользователь)': moment(item.end).format('DD.MM.YYYY HH:mm'),
                            'Кол-во часов:минут': moment.utc().startOf('day').add({ minutes: item.intervalMinutes }).format('HH:mm')
                        }
                    })
                    return result;
                })
                .flat();
            const ws = utils.json_to_sheet(logs);
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "Data");
            writeFileXLSX(wb, fileName+".xlsx");
        }
    }, [dispatch]);

    const onDownloadActivateSummary = useCallback(async () => {
        const res = await dispatch(fetchPointsLogs('activate-summary'));

        if(!res || res.length === 0) {
            swal({
                title: "Данные отсутствуют",
                text: "Выберите другие фильтры",
                icon: "warning",
                dangerMode: true,
            })
            return;
        }

        if(!!res) {
            const logs = res.map(item => {
                return {
                    'Id города': item.city == null ? '-' : item.city.id == null ? '-' : item.city.id,
                    'Город': item.city == null ? '-' : item.city.name == null ? '-' : item.city.name,
                    'Id филиала': item.point == null ? '-' : item.point.id == null ? '-' : item.point.id,
                    'Филиал': item.point == null ? '-' : item.point.street == null ? '-' : item.point.street,
                    'Кол-во часов остановки полной': moment.utc().startOf('day').add({ minutes: item.disabledAll }).format('HH:mm'),
                    'Кол-во часов остановки доставки': moment.utc().startOf('day').add({ minutes: item.disabledDelivery }).format('HH:mm'),
                    'Кол-во часов остановки самовывоза': moment.utc().startOf('day').add({ minutes: item.disabledOwn }).format('HH:mm'),
                }
            })
            const ws = utils.json_to_sheet(logs);
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "Data");
            writeFileXLSX(wb, "ActivateSummary.xlsx");
        }
    }, [dispatch]);

    const onDownloadMinDeliveryTimeSummary = useCallback(async () => {
        const res = await dispatch(fetchPointsLogs('minDeliveryTime-summary'));

        if(!res || res.length === 0) {
            swal({
                title: "Данные отсутствуют",
                text: "Выберите другие фильтры",
                icon: "warning",
                dangerMode: true,
            })
            return;
        }

        if(!!res) {
            const logs = res.map(item => {
                return {
                    'Id города': item.city == null ? '-' : item.city.id == null ? '-' : item.city.id,
                    'Город': item.city == null ? '-' : item.city.name == null ? '-' : item.city.name,
                    'Id филиала': item.point == null ? '-' : item.point.id == null ? '-' : item.point.id,
                    'Филиал': item.point == null ? '-' : item.point.street == null ? '-' : item.point.street,
                    'Мин. время доставки 60 кол-во часов': moment.utc().startOf('day').add({ minutes: item.minDeliveryTime60 }).format('HH:mm'),
                    'Мин. время доставки 75 кол-во часов': moment.utc().startOf('day').add({ minutes: item.minDeliveryTime75 }).format('HH:mm'),
                    'Мин. время доставки 90 кол-во часов': moment.utc().startOf('day').add({ minutes: item.minDeliveryTime90 }).format('HH:mm'),
                    'Мин. время доставки 105 кол-во часов': moment.utc().startOf('day').add({ minutes: item.minDeliveryTime105 }).format('HH:mm'),
                    'Мин. время доставки 120 кол-во часов': moment.utc().startOf('day').add({ minutes: item.minDeliveryTime120 }).format('HH:mm'),
                }
            });
            const ws = utils.json_to_sheet(logs);
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "Data");
            writeFileXLSX(wb, "MinDeliveryTimeSummary.xlsx");
        }
    }, [dispatch]);

    const onDownloadMinSamTimeSummary = useCallback(async () => {
        const res = await dispatch(fetchPointsLogs('minSamTime-summary'));

        if(!res || res.length === 0) {
            swal({
                title: "Данные отсутствуют",
                text: "Выберите другие фильтры",
                icon: "warning",
                dangerMode: true,
            })
            return;
        }

        if(!!res) {
            const logs = res.map(item => {
                return {
                    'Id города': item.city == null ? '-' : item.city.id == null ? '-' : item.city.id,
                    'Город': item.city == null ? '-' : item.city.name == null ? '-' : item.city.name,
                    'Id филиала': item.point == null ? '-' : item.point.id == null ? '-' : item.point.id,
                    'Филиал': item.point == null ? '-' : item.point.street == null ? '-' : item.point.street,
                    'Мин. время самовывоза 20 кол-во часов': moment.utc().startOf('day').add({ minutes: item.minSamTime20 }).format('HH:mm'),
                    'Мин. время самовывоза 30 кол-во часов': moment.utc().startOf('day').add({ minutes: item.minSamTime30 }).format('HH:mm'),
                    'Мин. время самовывоза 40 кол-во часов': moment.utc().startOf('day').add({ minutes: item.minSamTime40 }).format('HH:mm'),
                    'Мин. время самовывоза 50 кол-во часов': moment.utc().startOf('day').add({ minutes: item.minSamTime50 }).format('HH:mm'),
                    'Мин. время самовывоза 60 кол-во часов': moment.utc().startOf('day').add({ minutes: item.minSamTime60 }).format('HH:mm'),
                }
            });
            const ws = utils.json_to_sheet(logs);
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "Data");
            writeFileXLSX(wb, "MinSamTimeSummary.xlsx");
        }
    }, [dispatch]);

    const renderAllLogs = useMemo(() => (
        <DownloadLabel download={onDownloadPointsLogs} label={"Филиалы - все логи"}/>
    ), [onDownloadPointsLogs]);

    const renderActivateIntervals = useMemo(() => (
        <DownloadLabel download={() => onDownloadIntervals('intervals', 'activate', 'ActivateIntervals')} label={"Филиалы - интервалы состояние"}/>
    ), [onDownloadIntervals]);

    const renderActivateSummary = useMemo(() => (
        <DownloadLabel download={onDownloadActivateSummary} label={"Филиалы - свод состояние"}/>
    ), [onDownloadActivateSummary]);

    const renderMinDeliveryTimeIntervals = useMemo(() => (
        <DownloadLabel download={() => onDownloadIntervals('intervals', 'minDeliveryTime', 'MinDeliveryTimeIntervals')} label={"Филиалы - интервалы мин. время доставки"}/>
    ), [onDownloadIntervals]);

    const renderMinDeliveryTimeSummary = useMemo(() => (
        <DownloadLabel download={onDownloadMinDeliveryTimeSummary} label={"Филиалы - свод мин. время доставки"}/>
    ), [onDownloadMinDeliveryTimeSummary]);

    const renderMinSamTimeIntervals = useMemo(() => (
        <DownloadLabel download={() => onDownloadIntervals('intervals', 'minSamTime', 'MinSamTimeIntervals')} label={"Филиалы - интервалы мин. время самовывоза"}/>
    ), [onDownloadIntervals]);

    const renderMinSamTimeSummary = useMemo(() => (
        <DownloadLabel download={onDownloadMinSamTimeSummary} label={"Филиалы - свод мин. время самовывоза"}/>
    ), [onDownloadMinSamTimeSummary]);

    return (
        <>
            <div className="kt-portlet">
                <div className="kt-portlet__head" style={{ flexDirection: 'column', gap: '10px' }}>
                    <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">
                            Настройки
                        </h3>
                    </div>
                    <div className="kt-portlet__head-toolbar" style={{ flexWrap: 'wrap', gap: '10px' }}>
                        <div className="dateBlock">
                            <DateInput
                                disabled={loading}
                                placeholderText="Дата от"
                                selected={filters.createdStartDate}
                                onChange={onChangeDateInput('createdStartDate')}
                                maxDate={moment().toDate()}
                            />
                            <div>-</div>
                            <DateInput
                                disabled={loading}
                                placeholderText="Дата до"
                                selected={filters.createdEndDate}
                                onChange={onChangeDateInput('createdEndDate')}
                                maxDate={moment().toDate()}
                                required={filters.createdStartDate}
                            />
                        </div>

                        <div style={{ width: 250 }}>
                            <Select
                                isLoading={loadingCity || loading}
                                isDisabled={loadingCity || loading}
                                value={filters.city}
                                closeMenuOnSelect={true}
                                options={cities.map(item => ({
                                    value: item.id,
                                    label: item.name
                                }))}
                                onChange={setFilters('city')}
                                placeholder="Город"
                                noOptionsMessage={() => 'Нет городов'}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {renderAllLogs}
            {renderActivateSummary}
            {renderActivateIntervals}
            {renderMinDeliveryTimeSummary}
            {renderMinDeliveryTimeIntervals}
            {renderMinSamTimeSummary}
            {renderMinSamTimeIntervals}
        </>         
    );
    
}

export default Points;