import React from 'react';
import { useSelector } from 'react-redux';
import { fromStore } from '../../../selectors';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { deleteKassaAccount, setActiveModalAction } from '../../../action';
import swal from 'sweetalert';
import ActionMenu from '../../../components/utils/ActionMenu';

export const ItemRow = ({ item, UImobile }) => {
    const dispatch = useDispatch();
    const loaderUpdateKassa = useSelector(fromStore.loaderUpdateKassaSelector);
    const loaderDeleteKassa = useSelector(fromStore.loaderDeleteKassaSelector);
    const loaderUpdate = loaderUpdateKassa === item.id;
    const loadertDelete = loaderDeleteKassa === item.id;

    const onOpenModal = (id) => {
        const accountId = id || 'new';
        dispatch(setActiveModalAction({ field: 'yookassa', value: accountId }));
    }

    const onDeleteAccount = () => {
        swal({
            title: `Удаление аккаунта`,
            text: `Вы действительно хотите удалить ${item.name}?`,
            icon: "warning",
            dangerMode: true,
            buttons: ["Отмена", "Удалить"],
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(deleteKassaAccount(item.id));
            }
        });
    }

    const actions = [
        { name: 'Редактировать', icon: 'la la-edit', action: () => onOpenModal(item.id)},
        { name: 'Удалить', icon: 'la la-trash', action: () => onDeleteAccount()}
    ];    

    const renderBodyMobile = () => {
        return(
            <div className='mobile_div_flex column padding10px'>
                <div className='mobile_div_flex gap5px'>
                    <div style={{ width: '100%' }}>
                        <span style={{ color: '#4f62b5' }}>ID: </span> <span style={{ color: '#000' }}>{item.id}</span> 
                    </div>
                </div>
                <div className='mobile_div_flex'>
                    <div style={{ width: '100%' }}>
                        <span style={{ color: '#4f62b5' }}>Название: </span> <span style={{ color: '#000' }}>{item.name}</span> 
                    </div>
                </div>
                <div className='mobile_div_flex'>
                    <div style={{ width: '100%' }}>
                        <span style={{ color: '#4f62b5' }}>Дата создания: </span> <span style={{ color: '#000' }}>{moment(item.created_at).format('DD.MM.YYYY HH:mm')}</span> 
                    </div>
                </div>
                <div className='mobile_div_flex column gap5px'>
                    <div style={{ width: '100%' }}>
                        <span style={{ color: '#4f62b5' }}>Секретный ключ: </span> 
                    </div>
                    <div className='mobile_div_flex wrap' style={{ width: '100%' }}>
                        <span className='yookasa_mobile_secret' style={{ color: '#000' }}>{item.secret}</span> 
                    </div>
                    <div className='mobile_div_flex' style={{ width: 'calc(100% + 40px)', justifyContent: 'flex-end'}}>
                        <ActionMenu actions={actions}/>
                    </div>
                </div>

            </div>
        );
    }

    if(window.innerWidth < 940)
        return(
            <tr key={`${item.id}_slider`} className="kt-datatable__row">
                {renderBodyMobile()}
            </tr>
        );

    return (
        <tr key={`${item.id}_slider`} className="kt-datatable__row">
            <td width="80px" className="kt-datatable__cell"><span>{item.id}</span></td>
            <td className={`kt-datatable__cell${UImobile.openSidebar ? ' openSidebar' : ''} yookassa_table_name`}>
                <span>{item.name}</span>
            </td>
            <td width="auto" className="kt-datatable__cell"><span>{item.secret}</span></td>
            <td className={`kt-datatable__cell${UImobile.openSidebar ? ' openSidebar' : ''} yookassa_date`}><span>{moment(item.created_at).format('DD.MM.YYYY HH:mm')}</span></td>
            <td align='right' className={`kt-datatable__cell${UImobile.openSidebar ? ' openSidebar' : ''} table_action`}>
                {!loadertDelete && !loaderUpdate && <ActionMenu actions={actions}/>} 
                {(loadertDelete || loaderUpdate) && <span style={{overflow: 'visible', position: 'relative', width: '80px'}}><button className='btn btn-sm btn-clean btn-icon btn-icon-md kt-spinner kt-spinner--center kt-spinner--sm kt-spinner--danger'></button></span> }
            </td>
        </tr>
    );
}
