import { baseUrl } from "../constants/app";

export function declOfNum(number, titles) {  
    const cases = [2, 0, 1, 1, 1, 2];  
    return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];  
}

export function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

export const getImageUrl = (image) => image ? `${baseUrl}${image.url}` : '';

export const getFileUrl = (file) => file ? `${baseUrl}${file.url}` : '';

export const createFormData = (data) => {
    const formData = new FormData();
    const result = {};
    Object.entries(data).forEach(([key, value]) => {
        if (key === 'image' || key === 'imagePrev' || key === 'file' || key === 'secondImage') {
            if (value) {
                formData.append(`files.${key}`, value, value.name);
            } else {
                console.warn(`Значение для ${key} равно null`);
                result[key] = null
            }
        } else {
            result[key] = value;
        }
    });
    formData.append('data', JSON.stringify(result));
    return formData;
}

export const getNewPrice = (price = 0, condition, value = 0, persent, active = true) => {
    if (!price) return 0;
    if (!active) return price
    if (condition === 'equal') return value;
    if (persent) {
        const persentPrice = Math.round(price * (value / 100));
        if (condition === 'plus') {
            return price + persentPrice;
        }
        return price - persentPrice;
    } else {
        if (condition === 'plus') {
            return price + value;
        }
        if (condition === 'multiply') {
            return price * value;
        }
        return price - value;
    }
};