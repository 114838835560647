import React, { Component } from 'react';
import ActionMenu from '../ActionMenu';
import { withRouter  } from 'react-router-dom';
import moment from 'moment';
import { roleId } from '../../../constants/app';
import { connect } from 'react-redux';

class Table extends Component {
    renderThead() {
        return (
            <thead className="kt-datatable__head">
                <tr className="kt-datatable__row">
                    <th className="kt-datatable__cell">
                        <span className={`client_table-info${this.props.UImobile.openSidebar ? ' openSidebar' : ''}`}>Телефон</span>
                    </th>
                    <th className="kt-datatable__cell">
                        <span className={`client_table-info${this.props.UImobile.openSidebar ? ' openSidebar' : ''}`}>Имя</span>
                    </th>
                    {window.innerWidth > 880 && <th className="kt-datatable__cell">
                        <span className={`client_table-info${this.props.UImobile.openSidebar ? ' openSidebar' : ''}`}>Дата регистрации</span>
                    </th>}
                    {window.innerWidth > 350 && <th className="kt-datatable__cell">
                        <span className={`client_table-info${this.props.UImobile.openSidebar ? ' openSidebar' : ''}`}>Город</span>
                    </th>}
                    {window.innerWidth > 650 && <th className="kt-datatable__cell">
                        <span className={`client_table-info${this.props.UImobile.openSidebar ? ' openSidebar' : ''}`}>Адрес доставки</span>
                    </th>}
                    {window.innerWidth > 770 && <th className="kt-datatable__cell">
                        <span className={`client_table-info${this.props.UImobile.openSidebar ? ' openSidebar' : ''}`}>Пункт самовывоза</span>
                    </th>}
                    {window.innerWidth > 550 && <th className="kt-datatable__cell">
                        <span className={`client_table-info${this.props.UImobile.openSidebar ? ' openSidebar' : ''} status`}>
                            Статус
                        </span>
                    </th>}
                    <th className="kt-datatable__cell">
                    <span className={`client_table-info${this.props.UImobile.openSidebar ? ' openSidebar' : ''} action`}>Действия</span>
                    </th>
                </tr>
            </thead>
        );
    }
    
    handleDoubleClick = (item) => () => {
        this.props.history.push(`/clients/${+item.id}`)
    }
    
    renderAdress(item) {
        if (!item.street) return '-';
        if (item.privateHouse) {
            return `${item.street}, ${item.house} (Частный дом)`;
        }
        return `${item.street}, ${item.house}${item.entrance ? `, подъезд ${item.entrance}` : ''}${item.room ? `, кв./оф. ${item.room}` : ''}`;
    }

    renderBody() {

        return (
            <tbody className="kt-datatable__body">
                {
                    this.props.items.length === 0 && (
                        <tr className="kt-datatable__row">
                            <td className="kt-datatable__cell">Клиентов  не найдено</td>
                        </tr>
                    )
                }
                {
                    this.props.items.map(item => {
                        const actions = [
                            { name: 'Просмотр', icon: 'flaticon2-expand', action: () => this.props.history.push(`/clients/${+item.id}`) },
                            { name: 'Редактирование', access: [roleId.admin, roleId.chiefMarketingSpecialist], icon: 'flaticon2-contract', action: () => this.props.history.push(`/clients/edit/${+item.id}`) },
                            { name: 'Push сообщение', access: [roleId.admin, roleId.marketingSpecialist, roleId.chiefMarketingSpecialist], icon: 'flaticon2-mail-1', action: () => this.props.onUserMessage(item) },
                            { name: 'Удалить', access: roleId.admin, icon: 'flaticon2-trash', action: () => this.props.onDelete(item.id) }
                        ].filter(action => {
                            if (action.access && action.access !== this.props.role) {
                                return false;
                            }

                            if (action.name === "Push сообщение") {
                                if (item.deviceToken) {
                                    return true;
                                }
                                return false;
                            } 
                            
                            return true;
                            
                        });
                        return (
                            <tr onDoubleClick={this.handleDoubleClick(item)} key={item.id} className="kt-datatable__row">
                            <td className="kt-datatable__cell">
                                <span className={`client_table-info${this.props.UImobile.openSidebar ? ' openSidebar' : ''}`}>{item.phone ? item.phone : '---'}</span>
                            </td>
                            <td className="kt-datatable__cell">
                                <span className={`client_table-info${this.props.UImobile.openSidebar ? ' openSidebar' : ''}`}>{item.name ? item.name : '---'}</span>
                            </td>
                            {window.innerWidth > 880 && <td className="kt-datatable__cell">
                                <span className={`client_table-info${this.props.UImobile.openSidebar ? ' openSidebar' : ''}`}>{moment(item.created_at).format('DD.MM.YYYY HH:mm')}</span>
                            </td>}
                            {window.innerWidth > 350 && <td className="kt-datatable__cell">
                                <span className={`client_table-info${this.props.UImobile.openSidebar ? ' openSidebar' : ''}`}>{item.city ? item.city.name : '---'}</span>
                            </td>}
                            {window.innerWidth > 650 && <td className="kt-datatable__cell">
                                <span className={`client_table-info${this.props.UImobile.openSidebar ? ' openSidebar' : ''}`}>
                                  { item && this.renderAdress(item) }
                                </span>
                            </td>}
                            {window.innerWidth > 770 && <td className="kt-datatable__cell">
                                <span className={`client_table-info${this.props.UImobile.openSidebar ? ' openSidebar' : ''}`}>
                                   { item.points[0] && this.renderAdress(item.points[0]) }
                                </span>
                            </td>}
                            {window.innerWidth > 550 && <td className="kt-datatable__cell--sorted kt-datatable__cell">
                                <span className='client_table-info status'>
                                    {
                                        item.confirmed ? (
                                            <span className="kt-badge kt-badge--brand kt-badge--inline">активен</span>
                                        ) : (
                                            <span className="kt-badge kt-badge--warning kt-badge--inline">не подтвержден</span>
                                        )
                                    }
                                </span>
                            </td>}
                            <td className="kt-datatable__cell">
                                <ActionMenu actions={actions}/>
                            </td>
                        </tr>
                        )
                    }) 
                }
            </tbody>
        );
    }

    render() {
        return (
            <table className="kt-datatable__table" style={{ minHeight: '500px' }}>
                {this.renderThead()}
                {this.renderBody()}
            </table>
        );
    }
}

const mapStateToProps = state => ({
    UImobile: state.UImobile
});

export default connect(mapStateToProps)(withRouter(Table));