import React from 'react';
import Content from '../components/layout/Content';
import ErrorContainer from '../components/utils/ErrorContainer';
import { useSelector } from 'react-redux';
import { fromStore } from '../selectors';
import { useDispatch } from 'react-redux';
import { fetchKassaAccounts, setActiveModalAction } from '../action';
import { KassaAccountsList } from '../containers';
import HeadMobileActionMenu from '../components/utils/HeadMobileActionMenu';

function YooKassaPage() {
    const dispatch = useDispatch();
    const count = useSelector(fromStore.countKassaSelector);

    const onRefresh = () => {
        dispatch(fetchKassaAccounts());
    };

    const onOpenModal = (id) => {
        const accountId = id || 'new';
        dispatch(setActiveModalAction({ field: 'yookassa', value: accountId }));
    }

    const renderSubHeader = () => {
        return (
            <div className="kt-subheader not-mt kt-grid__item">
                <div className="kt-container  kt-container--fluid">
                <div className="kt-subheader__main">
                    <h3 className="kt-subheader__title">ЮКасса</h3>
                    <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                    <div className="kt-subheader__group" id="kt_subheader_search">
                        <span className="kt-subheader__desc" id="kt_subheader_total">
                            {count}
                        </span>
                    </div>
                </div>
                <div className="kt-subheader__toolbar">
                    {window.innerWidth > 1024 && <button onClick={(() => onOpenModal())} className="btn btn-success btn-icon-sm">Добавить аккаунт</button>}
                    <button onClick={onRefresh} className="btn btn-secondary btn-icon"><i className="la la-refresh"></i></button>
                </div>
            </div>
            </div>
        );
    };

    const actionsMobile =[
                { name: 'Добавить аккаунт', icon: 'flaticon2-plus', action: () => onOpenModal()}
            ]

    return (
        <Content>
            <HeadMobileActionMenu actions={actionsMobile}/>
            { renderSubHeader() }
            <div className='kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid'>
                <ErrorContainer field="yookassa" hasCloseButton={true}/>
                <div className="kt-grid kt-grid--desktop kt-grid--ver-desktop kt-todo">
                    <KassaAccountsList />
                </div>
            </div>
        </Content>
    );
}

export default YooKassaPage;