import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';
import { calculationTotalPriceNewOrder, changeChangeCashNewOrder, changeInformationNewOrder, changeDeliveryMethodNewOrder, changePaymentNewOrder, changePointNewOrder, setZonesNewOrder } from "../../action/neworder";
import request from "../../action/utils";
import { roleId, urls } from "../../constants/app";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";
import moment from "moment";
registerLocale("ru", ru);

function DetailNewOrder(props) {
    const { editOrder } = props;
    const filials = useSelector(state => state.handbook.filials);
    const loadingFilials = useSelector(state => state.loaders.filials);
    const loadingFilialsNewOrder = useSelector(state => state.loaders.loadingFilialsNewOrder);
    const neworders = useSelector(state => state.neworders);    

    const dispatch = useDispatch();

    const [inputChangeCash, setInputChangeCash] = useState('');
    const [preOrder, setPreOrder] = useState(false);
    const [openInfoPoint, setOpenInfoPoint] = useState(false)
    const [openPromotionDescription, setOpenPromotionDescription] = useState(false)

    useEffect(() => {
        setInputChangeCash('');
        if(neworders.visible_datetime)
            setPreOrder(true);
    }, [neworders.changeCash, neworders.visible_datetime])

    useEffect(() => {
        setOpenInfoPoint(false);
        setOpenPromotionDescription(false);
    }, [neworders.point])

    const handleChangePoint = (id) => {
        dispatch(changePointNewOrder(null));

        if(neworders.delivertPrice > 0)
            dispatch(calculationTotalPriceNewOrder());

        dispatch(setZonesNewOrder(null));
        dispatch({ type: 'SET_LOADER', field: 'loadingFilialsNewOrder', value: true });
        return request({ method: 'get', url: `${urls.filials}/${id}` })
        .then((response) => {
            fetchZones(id);
            dispatch({ type: 'SET_LOADER', field: 'loadingFilialsNewOrder', value: false });
            dispatch(changePointNewOrder(response));
            return response;
        })
        .catch(error => {
            dispatch({ type: 'SET_LOADER', field: 'loadingFilialsNewOrder', value: false });
            return null
        });
    }

    const fetchZones = (id) => {
        return request({ method: 'get', url: `${urls.zone}`, params: { 'point.id': id } })
            .then((response) => {
                dispatch(setZonesNewOrder(response));
                return response;
            })
            .catch(error => {
                dispatch(setZonesNewOrder(null));
                return null
            });
    }
    

    const handleInputChangeCash = (e) => {
        if(e.target.value <= 999999 && e.target.value > 0)
            return setInputChangeCash(parseFloat(e.target.value).toFixed(0))

        if(e.target.value > 999999)
            return setInputChangeCash(999999)

        if(e.target.value <= 0)
            return setInputChangeCash('')
    };

    const renderAdress = (item) => {
        return `${item.city.name ? item.city.name : neworders.city ? neworders.city.name : '---'}, ${item.street}, ${item.house}`;
    };

    const pointValue = neworders.point && {
        value: neworders.point.id,
        label: renderAdress(neworders.point)
    };

    const getStatusPay = () => {
        if (!neworders.paymentId) return 'Не оплачено';
        const checkPaymentData = neworders.paymentData ? JSON.parse(neworders.paymentData) : null;

        if (!checkPaymentData) 
            return 'Не оплачено';

        if (checkPaymentData.status === 'pending') 
            return 'Ожидает оплаты';
          
      
        if (checkPaymentData.status === 'succeeded') 
            return 'Оплачено';

        if (checkPaymentData.status === 'refundPending') 
            return 'Возврат средств';

        if (checkPaymentData.status === 'refund') 
            return 'Возврат средств выполнен';
        
        return '';
    }

     const renderAuthorRole = (role) => {
        switch(role) {
            case(role = roleId.manager):{
                return 'Управляющий'
            }
            case(role = roleId.admin):{
                return 'Администратор'
            }
            case(role = roleId.coordinator):{
                return 'Координатор'
            }
            case(role = roleId.operator):{
                return 'Оператор'
            }
            case(role = roleId.cashier):{
                return 'Кассир'
            }
            case(role = roleId.commodityExpert):{
                return 'Товаровед'
            }
            case(role = roleId.accountant):{
                return 'Бухгалтер'
            }
            case(role = roleId.marketingSpecialist):{
                return 'Маркетолог'
            }
            case(role = roleId.regionalDirector):{
                return 'Региональный директор'
            }
            case(role = roleId.chiefCommoditySpecialist):{
                return 'Главный товаровед'
            }
            case(role = roleId.chiefAccountant):{
                return 'Главный бухгалтер'
            }
            case(role = roleId.chiefMarketingSpecialist):{
                return 'Главный маркетолог'
            }
            default: {
                return 'Не найдена'
            }
        }
    }

    const renderSelect = () => {
        return(
            <div style={{ display: 'flex', flexDirection: 'column'}}>
                <div className="select_detail_body">
                    <div style={{width: '100%'}}>
                        <Select
                            isLoading={loadingFilials || loadingFilialsNewOrder}
                            isDisabled={loadingFilials || loadingFilialsNewOrder}
                            closeMenuOnSelect={true}                    
                            options={filials.filter((item) => !item.hiddenClient)
                                .sort((a, b) => {
                                const adressA = renderAdress(a);
                                const adressB = renderAdress(b);
                                if (adressA < adressB) return -1;
                                if (adressA > adressB) return 1;
                                return 0;
                                })
                                .map(item => ({
                                    value: item.id,
                                    label: renderAdress(item)
                                }))}
                            onChange={((e) => handleChangePoint(e.value))}
                            placeholder="Филиал"
                            noOptionsMessage={() => 'Нет филиалов'}
                            value={pointValue}                    
                        />
                    </div>
                    {neworders.point && 
                        <button 
                        className="order_position_list_inf_button info_point" 
                        onClick={(() => setOpenInfoPoint(!openInfoPoint))} 
                        onBlur={(() => setOpenInfoPoint(false))}>
                            i
                        </button>
                    }
                </div>
                    {openInfoPoint &&
                        renderInfoPoint()
                    }
            </div>   
        )
    }

    const renderPointTime = (point) => {
        let deliveryTime = 60;
        let samTime = 20;
        if(point) {
            if(point.activate === 'disabledAll')
                return 'СТОП';

            if(point.activate === 'disabledDelivery') {
                deliveryTime = 'СТОП';
            } else {
                deliveryTime = point.minDeliveryTime
            }

            if(point.activate === 'disabledOwn') {
                samTime = 'СТОП';
            } else {
                samTime = point.minSamTime
            }

            return `${deliveryTime}/${samTime}`
        }
        return 'Не определено'
    }

    const renderTodayMinTime = (point) => {
        if(neworders.deliveryMethod === 'own') {
            if(point.minSamTime && point.activate !== 'disabledAll' && point.activate !== 'disabledOwn')
                return new Date(new Date().getTime() + parseInt(point.minSamTime) * 60 * 1000);

            return new Date(new Date().getTime() + 20 * 60 * 1000);
        } else {
            if(point.minDeliveryTime && point.activate !== 'disabledAll'  && point.activate !== 'disabledDelivery')
                return new Date(new Date().getTime() + parseInt(point.minDeliveryTime) * 60 * 1000);

            return new Date(new Date().getTime() + 60 * 60 * 1000); 
        }
    }

    const renderDeliveryMethodBlock = () => {
        return(
            <>
             {neworders.point && neworders.point.comment &&
                    <div className="neworder_detail_information">
                        {neworders.point.comment}
                    </div>
                }
                {neworders.point && neworders.point.promotion &&
                    <div 
                    className={`neworder_detail_information ${neworders.point.promotionDescription ? ' promotionDescription' : ''}`}
                    tabIndex={1}
                    onClick={(() => {
                        if(neworders.point.promotionDescription)
                            setOpenPromotionDescription(!openPromotionDescription)
                    })}
                    onBlur={(() => {
                        if(neworders.point.promotionDescription)
                            setOpenPromotionDescription(false)
                    })}
                    >
                        Есть действующая акция   
                        {openPromotionDescription && 
                        <div className="order_position_footer_dropdown_container description">
                            <div className="order_position_footer_dropdown description" style={{marginTop: '5px'}} onMouseDown={((e) => e.preventDefault())}>
                                {neworders.point.promotionDescription}
                            </div>
                        </div>
                        }                     
                    </div>
                }   
                <div className="neworder_detail_information">
                    <span>Ожидание:</span>  
                    <span>{renderPointTime(neworders.point)}</span>
                </div>
                <div className="neworder_detail_information">
                        <span>
                            Предзаказ
                        </span>                        
                        {preOrder &&
                        <div style={{width: 'calc(100% - 90px'}}>
                            <DatePicker                                
                                selected={neworders.visible_datetime ? new Date(neworders.visible_datetime) : null}
                                className="form-control neworder"
                                style={{ position: 'absolute', width: '100%' }}
                                onChange={(date) => dispatch(changeInformationNewOrder('visible_datetime', date))}
                                showTimeSelect
                                timeIntervals={neworders.deliveryMethod === 'own' ? 5 : 15} 
                                dateFormat="dd/MM/yyyy HH:mm"
                                timeFormat="HH:mm"
                                locale="ru"
                                onKeyDown={(e) => e.preventDefault()}  
                                minDate={new Date()}
                                minTime={
                                    neworders.visible_datetime && new Date(neworders.visible_datetime).toDateString() === new Date().toDateString()
                                        ? renderTodayMinTime(neworders.point) 
                                        : neworders.deliveryMethod === 'own' ? new Date(new Date().setHours(10, 20)) : new Date(new Date().setHours(11, 0))
                                } 
                                maxTime={new Date().setHours(22, 55)} 
                            />
                        </div>}
                        <input 
                        type="checkbox"
                        checked={preOrder}
                        onChange={() => {
                            setPreOrder(!preOrder);
                            if(!preOrder)                            
                                dispatch(changeInformationNewOrder('visible_datetime', null));                            
                        }}
                        />    
                    </div>
                    {neworders.deliveryTime && 
                    <div className="neworder_detail_information">
                        <span>Заказ ко времени:</span>  
                        <span>{moment(neworders.deliveryTime).format('DD.MM.YYYY в HH:mm')}</span>
                    </div>}
                {editOrder && neworders.source !== 'crm' 
                ?          
                    <div className="neworder_detail_information">
                        <span>Источник:</span>  
                        <span>{neworders.isSite ? 'Сайт' : 'Приложение'}</span>
                    </div>
                :
                neworders.authorRole && <div className="neworder_detail_information">
                        <span>Роль автора:</span>  
                        <span>{renderAuthorRole(neworders.authorRole)}</span>
                    </div>
                }
                {editOrder && neworders.userName &&
                <div className="neworder_detail_information">
                        <span>Клиент:</span>  
                        {neworders.user ? (
                            <Link to={`/clients/${neworders.user.id}`}>{neworders.userName}</Link>
                        ) : (
                            <span>{neworders.userName || '-'}</span>
                        )}
                </div>
                }
                <div className="neworder_detail_information">
                    <span>Тип заказа:</span>  
                    <div style={{display: 'flex'}}>
                        <button
                        className={`neworder_detail_deliverymethod_button delivery ${neworders.deliveryMethod === 'delivery' ? 'actived' : ''}`}
                        onClick={(() => {
                            if(neworders.deliveryMethod !== 'delivery')
                                dispatch(changeDeliveryMethodNewOrder('delivery'));
                        })}
                        >
                            Доставка
                        </button>
                        <button
                        className={`neworder_detail_deliverymethod_button own ${neworders.deliveryMethod === 'own' ? 'actived' : ''}`}
                        onClick={(() => {
                            if(neworders.deliveryMethod !== 'own')
                                dispatch(changeDeliveryMethodNewOrder('own'));
                        })}
                        >
                            Вынос
                        </button>                        
                    </div>
                </div>
            </>
        );
    }

    const renderInputs = () => {
            return(
                <div className="neworder_detail_block">
                    <div className="neworder_detail_input_div">
                        <span className="neworder_detail_input_text">
                            Тел.
                        </span>
                        <input 
                            type="number"
                            className="form-control neworder"
                            autocomplete="false"
                            placeholder="Номер телефона"
                            value={neworders.phone ? neworders.phone : ''}
                            onChange={((e) => dispatch(changeInformationNewOrder('phone', parseInt(e.target.value.slice(0, 11)))))}
                            onBlur={((e) => {
                                if(String(parseInt(e.target.value)).length < 11)
                                    dispatch(changeInformationNewOrder('phone', null));

                                if(String(parseInt(e.target.value)).length > 11)
                                    dispatch(changeInformationNewOrder('phone', parseInt(e.target.value.slice(0, 11))));
                            })}
                        />
                    </div>
                    {neworders.deliveryMethod === 'delivery' &&
                        <>
                            {neworders.area &&
                            <div className="neworder_detail_input_div">
                                <span className="neworder_detail_input_text">
                                    Район
                                </span>
                                <input 
                                    type="text"
                                    className="form-control neworder"
                                    autocomplete="false"
                                    placeholder="Район"
                                    value={`${neworders.area ? neworders.area : ''}`}
                                    onChange={((e) => dispatch(changeInformationNewOrder('area', e.target.value)))}
                                />
                            </div>
                            }
                            <div className="neworder_detail_input_div">
                                <span className="neworder_detail_input_text">
                                    Ул.
                                </span>
                                <input 
                                    type="text"
                                    className="form-control neworder"
                                    autocomplete="false"
                                    placeholder="Улица"
                                    value={`${neworders.street ? neworders.street : ''}`}
                                    onChange={((e) => dispatch(changeInformationNewOrder('street', e.target.value)))}
                                />
                            </div>
                            <div className="neworder_detail_input_div">
                                <span className="neworder_detail_input_text">
                                    Д.
                                </span>
                                <input 
                                    type="text"
                                    className="form-control neworder"
                                    autocomplete="false"
                                    placeholder="Дом"
                                    value={neworders.house ? neworders.house : ''}
                                    onChange={((e) => dispatch(changeInformationNewOrder('house', e.target.value)))}
                                />
                            </div>
                            <div className="neworder_detail_input_div">
                                <span className="neworder_detail_input_text">
                                    Под.
                                </span>
                                <input 
                                    type="text"
                                    className="form-control neworder"
                                    autocomplete="false"
                                    placeholder="Подъезд"
                                    value={neworders.entrance ? neworders.entrance : ''}
                                    onChange={((e) => dispatch(changeInformationNewOrder('entrance', e.target.value)))}
                                />
                            </div>
                            <div className="neworder_detail_input_div">
                                <span className="neworder_detail_input_text">
                                    Эт.
                                </span>
                                <input 
                                    type="text"
                                    className="form-control neworder"
                                    autocomplete="false"
                                    placeholder="Этаж"
                                    value={neworders.floor ? neworders.floor : ''}
                                    onChange={((e) => dispatch(changeInformationNewOrder('floor', e.target.value)))}
                                />
                            </div>
                            <div className="neworder_detail_input_div">
                                <span className="neworder_detail_input_text">
                                    Кв.
                                </span>
                                <input 
                                    type="text"
                                    className="form-control neworder"
                                    autocomplete="false"
                                    placeholder="Кв"
                                    value={neworders.room ? neworders.room : ''}
                                    onChange={((e) => dispatch(changeInformationNewOrder('room', e.target.value)))}
                                />
                            </div>
                        </>
                    }
                    <div className="neworder_detail_input_div">
                        <textarea 
                            type="text"
                            className="form-control neworder"
                            style={{minHeight: '80px', maxHeight: '80px', width: '100%'}}
                            maxLength={265}
                            autocomplete="false"
                            placeholder="Комментарий"
                            value={neworders.comment ? neworders.comment : ''}
                            onChange={((e) => dispatch(changeInformationNewOrder('comment', e.target.value)))}
                        />
                    </div>
                </div>
            );
    }

    const renderPaymentMethod = () => {
        if(neworders.payment === 'online') 
            return (
            <div className="neworder_detail_block">
                <button
                className={`order_position_list_footer_button payment actived`}
                >
                    {getStatusPay()}
                </button>
            </div>
            )
        return(
            <div className="neworder_detail_block">
                <button
                className={`order_position_list_footer_button payment ${neworders.payment === 'paymented' ? 'actived' : ''}`}
                onClick={(() => dispatch(changePaymentNewOrder('paymented')))}
                >
                    Оплачено
                </button>
                <button
                className={`order_position_list_footer_button payment ${neworders.payment === 'terminal' ? 'actived' : ''}`}
                onClick={(() => dispatch(changePaymentNewOrder('terminal')))}
                >
                    Терминал
                </button>
                {(neworders.payment !== 'cash' || neworders.changeCash) &&
                <button
                className={`order_position_list_footer_button payment ${neworders.payment === 'cash' ? 'actived' : ''}`}
                onClick={(() => dispatch(changePaymentNewOrder('cash')))}
                >                    
                    {
                        neworders.changeCash === 'no' ? 'Наличные без сдачи' 
                        :
                        neworders.changeCash === 'c1000' ? 'Наличные сдача с 2000 ₸' 
                        :
                        neworders.changeCash === 'c1500' ? 'Наличные сдача с 5000 ₸' 
                        :
                        neworders.changeCash === 'c2000' ? 'Наличные сдача с 10000 ₸'
                        :
                        neworders.changeCash === 'c5000' ? 'Наличные сдача с 20000 ₸'
                        :                
                        `Наличные ${neworders.changeCash ? `сдача с ${neworders.changeCash.slice(1)} ₸` : ''}`
                    }
                </button>
                }
                {neworders.payment === 'cash' && !neworders.changeCash &&
                <div className="detail_order_changecash_container">
                    <div className="detail_order_changecash_buttons_div">
                        <button
                        className={`order_position_list_footer_button payment${neworders.changeCash === 'no' ? 'actived' : ''}`}
                        onClick={(() => dispatch(changeChangeCashNewOrder('no')))}
                        >
                            Без сдачи
                        </button>
                        <button
                        className={`order_position_list_footer_button payment${neworders.changeCash === 'c1000' ? 'actived' : ''}`}
                        onClick={(() => dispatch(changeChangeCashNewOrder('c1000')))}
                        >
                            Сдача с 2000 ₸
                        </button>
                        <button
                        className={`order_position_list_footer_button payment${neworders.changeCash === 'c1500' ? 'actived' : ''}`}
                        onClick={(() => dispatch(changeChangeCashNewOrder('c1500')))}
                        >
                            Сдача с 5000 ₸
                        </button>
                    </div>
                    <div className="detail_order_changecash_buttons_div">
                        <button
                        className={`order_position_list_footer_button payment${neworders.changeCash === 'c2000' ? 'actived' : ''}`}
                        onClick={(() => dispatch(changeChangeCashNewOrder('c2000')))}
                        >
                            Сдача с 10000 ₸
                        </button>
                        <button
                        className={`order_position_list_footer_button payment${neworders.changeCash === 'c5000' ? 'actived' : ''}`}
                        onClick={(() => dispatch(changeChangeCashNewOrder('c5000')))}
                        >
                            Сдача с 20000 ₸
                        </button>
                    </div>
                    <div style={{ display: 'flex', gap: '5px', width: '100%'}}>
                        <input 
                        className="new_order_input"
                        style={{ width: '100%'}}
                        type="number"
                        placeholder="Другая сумма"
                        value={inputChangeCash}
                        onChange={handleInputChangeCash}
                        />
                        <button 
                        className="order_position_list_footer_button"
                        style={{ padding: '0px', height: '1.6rem', minWidth: '2rem'}}
                        onClick={(() => {
                            if(inputChangeCash)                            
                                dispatch(changeChangeCashNewOrder('c' + inputChangeCash));                            
                        })}
                        >
                            <i className="flaticon2-check-mark"/>
                        </button>
                    </div>
                </div>
                }
            </div>
        )
    }

    const renderInfoPoint  = () => {
        return (
            <div className="order_position_footer_dropdown_container description">
                <div className="order_position_footer_dropdown description" style={{marginTop: '5px', padding: '0px'}} onMouseDown={((e) => e.preventDefault())}>
                    {neworders.point.onlinePay &&
                    <div className="neworder_comment_point"> 
                        Есть оплата ЮКасса
                    </div>
                    }
                    {neworders.point.qrPayment &&
                    <div className="neworder_comment_point">
                        Есть оплата QR
                    </div>
                    }
                    <div className="neworder_comment_point">
                        {neworders.point.DeliveryToTheEntrance ? 'Доставка до подъезда' : 'Доставка до квартиры'}
                    </div>
                    {neworders.point.cafe &&
                    <div className="neworder_comment_point">
                        Работает как кафе
                    </div>
                    }                    
                </div>
            </div>
        )
    }

    return(
        <div className="neworder_detail_body">
            {renderSelect()}
            {(neworders.point || editOrder) && !loadingFilialsNewOrder && renderDeliveryMethodBlock()}
            {(neworders.point || editOrder) && !loadingFilialsNewOrder && renderInputs()}
            {(neworders.point || editOrder) && !loadingFilialsNewOrder && renderPaymentMethod()}
        </div>
    );
}

export default DetailNewOrder;