import React, { useEffect, useRef, useState } from "react"
import Select from 'react-select';
import { useOnClickOutside } from "../../hooks";
import { useDispatch, useSelector } from "react-redux";
import { setActiveModalAction } from "../../action";
import { withRouter  } from 'react-router-dom';
import Input from "../../components/utils/Input";
import moment from "moment";
import { fetchOrders, setOrdersFilters, setOrdersPaging } from "../../action/orders";
const SearchOrderModal = () => {
    const ref = useRef();
    const dispatch = useDispatch();
    const filters = useSelector(state => state.orders.filters);
    const paging = useSelector(state => state.orders.paging);
    const [typeSearch, setTypeSearch] = useState({label: 'Номеру заказа', value: 'id'});
    const [inputValue, setInputValue] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [createdDateOnUpdate, setCreatedDateOnUpdate] = useState({})
    const getDateValue = (type) => {
        const date = new Date()
        switch(type){
            case 'yesterday':{
                return {Min: moment(date).subtract(1,'days').format('YYYY-MM-DD 00:00:00'), Max: moment(date).subtract(1,'days').format('YYYY-MM-DD 23:59:59')};
            }
            case 'week':{
                let monday = moment(date);
                monday.startOf('isoWeek'); 
    
                let sunday = moment(date);
                sunday.endOf('isoWeek'); 
    
                return {Min: monday.format('YYYY-MM-DD 00:00:00'), Max: sunday.format('YYYY-MM-DD 23:59:59')};
            }
            case 'month':{
                return {Min: moment(date).startOf('month').format('YYYY-MM-DD 00:00:00'), Max: moment(date).endOf('month').format('YYYY-MM-DD 23:59:59')};
            }
            case 'year':{
                return {Min: moment(date).startOf('year').format('YYYY-MM-DD 00:00:00'), Max: moment(date).endOf('year').format('YYYY-MM-DD 23:59:59')};
            }
            default: {
                return {Min: moment(date).format('YYYY-MM-DD 00:00:00'), Max: moment(date).format('YYYY-MM-DD 23:59:59')};
            }
        }
    }
    const dateValue = [
                {label: `Сегодня ${moment(getDateValue('today').Min).format('DD.MM.YYYY')}`, value: getDateValue('today')},
                {label: `Вчера ${moment(getDateValue('yesterday').Min).format('DD.MM.YYYY')}`, value: getDateValue('yesterday')},
                {label: 'Текущая неделя', value: getDateValue('week')},
                {label: 'Текущий месяц', value: getDateValue('month')},
                {label: `${moment(getDateValue('year').Min).year()} год`, value: getDateValue('year')},
            ]
    useEffect(() => {
        setSelectedDate(dateValue[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const changeTypeSearch = (e) => {
        setTypeSearch(e)
    }
    const onChangeDateSelect = (e) => {  
        setCreatedDateOnUpdate({createdStartDate: moment(e.value.Min).toDate(), createdEndDate: moment(e.value.Max).toDate()})      
        setSelectedDate(e);
    }
    const onChangeInput = (e) => {
        setInputValue(e.target.value)
    }
    const onClose = () => dispatch(setActiveModalAction({ field: 'orderSearch', value: null }));
    
    useOnClickOutside(ref, onClose);
    const onSubmit = () => {
        const isArchive = window.location.pathname.includes('/archive')
        const updated = {
            ...filters,
            [typeSearch.value]: parseInt(inputValue),
            createdStartDate: createdDateOnUpdate.createdStartDate,
            createdEndDate: createdDateOnUpdate.createdEndDate
        };
        dispatch(setOrdersFilters(updated));
        dispatch(setOrdersPaging({ ...paging, start: 0 }));
        dispatch(fetchOrders({}, isArchive));
        const resetFilters = {
            ...filters,
            id: null,
            phone: null
        };
        dispatch(setOrdersFilters(resetFilters));
        onClose();
    }
    return(
        <form>
            <div  className="modal fade show" style={{ display: 'block' }}>
                <div className="modal-dialog modal-lg modal-dialog-scrollable" style={{ height: '90%', alignItems: 'center', justifyContent: 'center' }}>
                    <div ref={ref} className="modal-content" style={{ maxWidth: '350px' }}>
                        <div className="modal-header">
                            <h5 className="modal-title"> Поиск заказа </h5>
                            <button onClick={onClose} type="button" className="close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group" style={{ marginBottom: '0.5rem' }}>
                                <div style={{ marginBottom: '0.5rem' }}>Период</div>                               
                                <Select
                                    placeholder='Дата'
                                    value={selectedDate}
                                    onChange={(e) => onChangeDateSelect(e)}
                                    options={dateValue}
                                />
                            </div>  
                            <div className="form-group" style={{ marginBottom: '0.5rem' }}>
                                <div style={{ marginBottom: '0.5rem' }}>Поиск по</div> 
                                <Select
                                    placeholder='Тип поиска'
                                    value={typeSearch}
                                    onChange={(e) => changeTypeSearch(e)}
                                    options={[
                                        {label: 'Номеру заказа', value: 'id'},
                                        {label: 'Номеру телефона', value: 'phone'}
                                    ]}
                                />
                            </div>                              
                            <Input 
                            label={typeSearch.value === 'id' ? 'Номер заказа' : 'Номер телефона'}
                            required={true}
                            onChange={onChangeInput}
                            value={inputValue}
                            placeholder={typeSearch.value === 'id' ? 'Номер заказа' : 'Номер телефона'}
                            />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" onClick={onClose}>Закрыть</button>
                            <button disabled={(inputValue && typeSearch)? false : true} type="button" onClick={onSubmit} className='btn btn-primary'>Найти</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}
export default withRouter(SearchOrderModal);