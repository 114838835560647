import React, { Component } from 'react';
import ActionMenu from '../utils/ActionMenu';
import { withRouter  } from 'react-router-dom';
import { roleId } from '../../constants/app';
class TablePayments extends Component {    
    handleDoubleClick = (item) => () => {
        if(this.props.role === roleId.admin )
            this.props.history.push(`/handbook/payments/edit/${+item.id}`)
    }
    
    renderBody() {        
        return (
            <tbody className="kt-datatable__body">
                {
                    this.props.items.map(item => {
                        const actions = [
                            { access: roleId.admin, name: 'Редактирование', icon: 'flaticon2-contract', action: () => this.props.history.push(`/handbook/payments/edit/${+item.id}`) },
                            { access: roleId.admin, name: 'Удалить', icon: 'flaticon2-trash', action: () => this.props.deleteItem(item)}
                        ].filter(item => !item.access || item.access === this.props.role);
                        return (
                            <tr onDoubleClick={this.handleDoubleClick(item)} key={item.id} className="kt-datatable__row">
                            
                            <td className="kt-datatable__cell" style={{ width: '35%' }}>
                                <span>
                                    {item.VisibleName}
                                </span>
                            </td>
                            <td className="kt-datatable__cell" style={{ width: '25%' }}>
                                <span>
                                    {item.Name}
                                </span>
                            </td>
                            <td className="kt-datatable__cell" style={{ width: '35%' }}>
                                <span>
                                    {item.VisibleClient ? 'Виден клиентам' : 'Скрыт для клиентов'}
                                </span>
                            </td>
                            <td className="kt-datatable__cell">
                                <ActionMenu actions={actions}/>
                            </td>
                        </tr>
                        )
                    }) 
                }
            </tbody>
        );
    }

    renderBodyMobile(){
        return (
            <tbody className="kt-datatable__body">
                {
                    this.props.items.map(item => {
                        const actions = [
                            { access: roleId.admin, name: 'Редактирование', icon: 'flaticon2-contract', action: () => this.props.history.push(`/handbook/payments/edit/${+item.id}`) },
                            { access: roleId.admin, name: 'Удалить', icon: 'flaticon2-trash', action: () => this.props.deleteItem(item)}
                        ].filter(item => !item.access || item.access === this.props.role);
                        return (
                            <tr onDoubleClick={this.handleDoubleClick(item)} key={item.id} className="kt-datatable__row">
                            <div className='mobile_div_flex column padding10px'>
                                <div className='mobile_div_flex gap0px'>
                                    <div className='mobile_div_flex' style={{ width: '50%' }}>
                                        <span>
                                            {item.VisibleName}
                                        </span>
                                    </div>
                                    <div className='mobile_div_flex' style={{ width: '50%' }}>
                                        <span>
                                            {item.Name}
                                        </span>
                                    </div>
                                </div>
                                <div className='mobile_div_flex gap0px'>
                                    <div className='mobile_div_flex' style={{ width: 'calc(50% - 5px)' }}>
                                        <span>
                                            {item.VisibleClient ? 'Виден клиентам' : 'Скрыт для клиентов'}
                                        </span>
                                    </div>
                                    <div className='mobile_div_flex' style={{ width: '50%', justifyContent: 'flex-start' }}>
                                        <span>
                                            <ActionMenu actions={actions}/>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </tr>
                        )
                    }) 
                }
            </tbody>
        );
    }

    render() {
        if (this.props.items.length === 0) {
            return (
                <div>Способов оплаты не найдено</div>
            );
        }

        if(window.innerWidth < 500)
            return (
                <table className="kt-datatable__table" style={{ minHeight: '500px' }}>
                    {this.renderBodyMobile()}
                </table>
            );

        return (
            <table className="kt-datatable__table" style={{ minHeight: '500px' }}>
                {this.renderBody()}
            </table>
        );
    }
}
export default withRouter(TablePayments);