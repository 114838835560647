import React from 'react';
import moment from 'moment';

export const ItemRow = ({ item }) => {
    const renderBodyMobile = () => {
        return(
            <div className='mobile_div_flex column padding10px'>
                <div className='mobile_div_flex'>
                    <div style={{ width: '50%' }}>
                        Телефон:
                    </div>
                    <div style={{ width: '50%', color: '#000' }}>
                        {item.phone}
                    </div>
                </div>
                <div className='mobile_div_flex'>
                    <div style={{ width: '50%' }}>
                        Код:
                    </div>
                    <div style={{ width: '50%', color: '#000' }}>
                        {item.code}
                    </div>
                </div>
                <div className='mobile_div_flex'>
                    <div style={{ width: '50%' }}>
                        Дата отправки:
                    </div>
                    <div style={{ width: '50%', color: '#000' }}>
                        {moment(item.created_at).format('DD.MM.YYYY HH:mm')}
                    </div>
                </div>
                <div className='mobile_div_flex'>
                    <div style={{ width: '50%' }}>
                        IP:
                    </div>
                    <div style={{ width: '50%', color: '#000' }}>
                        {item.ip || '-'}
                    </div>
                </div>
            </div>
        );
    }

    if(window.innerWidth < 550)
        return(
            <tr key={`${item.id}_code`} className="kt-datatable__row">
                {renderBodyMobile()}
            </tr>
        );

    return (
        <tr key={`${item.id}_code`} className="kt-datatable__row">
            <td className="kt-datatable__cell" style={{ width: '120px' }}><span>{item.phone}</span></td>
            <td className="kt-datatable__cell" style={{ width: '80px' }}><span>{item.code}</span></td>
            <td className="kt-datatable__cell" style={{ width: '150px' }}>
                {moment(item.created_at).format('DD.MM.YYYY HH:mm')}
            </td>
            <td width="100px" className="kt-datatable__cell" style={{ width: 'auto' }}><span>{item.ip}</span></td>
        </tr>
    );
}
