import React from 'react';

export const TableHeader = () => (
    <thead className="kt-datatable__head">
        <tr className="kt-datatable__row">
            <th className="kt-datatable__cell" style={{ width: '120px' }}><span>Телефон</span></th>
            <th className="kt-datatable__cell" style={{ width: '80px' }}><span>Код</span></th>
            <th className="kt-datatable__cell" style={{ width: '150px' }}><span>Дата отправки</span></th>
            <th className="kt-datatable__cell" style={{ width: 'auto' }}><span>IP</span></th>
        </tr>
    </thead>
);