import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { fetchFilials, activateFilial, setHandbookPaging, deleteFilial, fetchCities, setHandbookFilters, setFilialEdit, updateFilial, fetchFilial, updateFilialsTimeDelivery, addFilialsEdit, changeFilialsEdit, removeFilialsEdit } from '../../action/handbook';
import { setError } from '../../action/errors';
import Loader from '../utils/Loader';
import TableFilials from './TableFilials';
import Portlet from '../utils/Portlet';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import swal from 'sweetalert';
import ErrorContainer from '../utils/ErrorContainer';
import Select from 'react-select';
import cn from 'classnames';
import { roleId, timeDelivery } from '../../constants/app'
import { fetchGoods, fetchCategories } from '../../action/goods';
import { createSelector } from 'reselect';
import HeadMobileActionMenu from '../utils/HeadMobileActionMenu';
import { setUIvisible } from '../../action/UImobile';

const styles = {
    loading: {
        minHeight: '500px',
        display: 'flex',
        justifyContent: 'center'
    }
}

class FilialList extends Component {
    componentDidMount() {
        this.props.fetchFilials();
        this.props.fetchCities();
        this.props.fetchGoods();
        this.props.fetchCategories();
        this.props.setUIvisible(true, true);
    }

    componentWillUnmount() {
        this.props.setHandbookFilters('filial', { city: null });
    }

    state = {
        currentPage: 0
    }

    resetTime = () => {
        this.props.updateFilials({minDeliveryTime: timeDelivery.minDeliveryTime, minSamTime: timeDelivery.minSamTime})
        .then(result => {
            if (result) {
                this.props.fetchFilials();
            }
        });
    }

    resetActivate = () => {
        this.props.updateFilials({activate: "active"})
        .then(result => {
            if (result) {
                this.props.fetchFilials();
            }
        });
    }

    setPageLimit = (e) => {
        e.preventDefault();
        const updatedPaging = { start: 0, limit: e.target.value};
        this.setState({ currentPage: 0 });
        this.props.setHandbookPaging('filial', updatedPaging);
        this.props.fetchFilials();
    }

    handlePageClick = data => {
        const { paging } = this.props;
        this.setState({ currentPage: data.selected }, () => {
            let nextStart =  data.selected * paging.limit;
            const updatedPaging = {...paging, start: nextStart};
            this.props.setHandbookPaging('filial', updatedPaging);
            this.props.fetchFilials();
        });
    };

    chekMarginPagesDisplayed = () => {
        const userDisplayWidth = window.innerWidth;
        if(userDisplayWidth < 400)
            return 1;
        if(userDisplayWidth < 550)
            return 2;
        if(userDisplayWidth < 850)
            return 3;
        if(userDisplayWidth < 1250)
            return 5;
        return 8;
    }
    chekPageRangeDisplayed = () => {
        const userDisplayWidth = window.innerWidth;
        if(userDisplayWidth < 550)
            return 2;
        if(userDisplayWidth < 850)
            return 3;
        return 5;
    }

    renderPagination() {
        const { count, paging } = this.props;
        const pages = Math.ceil(+count / +paging.limit);

        return (
            <div className="kt-datatable__pager kt-datatable--paging-loaded">
                <ReactPaginate
                    previousLabel={<i className="flaticon2-back"></i>}
                    nextLabel={<i className="flaticon2-next"></i>}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pages}
                    marginPagesDisplayed={this.chekMarginPagesDisplayed()}
                    pageRangeDisplayed={this.chekPageRangeDisplayed()}
                    forcePage={this.state.currentPage}
                    onPageChange={this.handlePageClick}
                    containerClassName={'kt-datatable__pager-nav'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    pageLinkClassName="kt-datatable__pager-link kt-datatable__pager-link-number"
                    previousLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--prev"
                    nextLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--next"
                    activeLinkClassName="kt-datatable__pager-link--active"
                    disabledClassName="kt-datatable__pager-link--disabled"
                />

                    <div className="kt-datatable__pager-info">
                        <div className="kt-datatable__pager-size">
                            <select onChange={this.setPageLimit} value={paging.limit} className="form-control">
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            
                        </div>
                        {
                            paging.start + paging.limit >= count ? (
                                <span className="kt-datatable__pager-detail">Показано {paging.start + 1} - {+count} из {count}</span>
                            ) : (
                                <span className="kt-datatable__pager-detail">Показано {paging.start + 1} - {+paging.start + +paging.limit} из {count}</span>
                            )
                        }
                       
                    </div>
            </div>
    
        );
    }

    setFilialsFilters = (field) => (data) => {
        const { filters, paging } = this.props;
        const updated = {
            ...filters,
            [field]: data
        };

        this.props.setHandbookFilters('filial', updated);
        this.props.setHandbookPaging('filial', { ...paging, start: 0 });
        this.props.fetchFilials();
    }

    renderSubHeader() {
        return (
            <div className="kt-subheader not-mt kt-grid__item">
                <div className="kt-container  kt-container--fluid ">
                <div className="kt-subheader__main">
                    <h3 className="kt-subheader__title">Филиалы</h3>
                    <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                    <div className="kt-subheader__group" id="kt_subheader_search">
                        <span className="kt-subheader__desc" id="kt_subheader_total">
                            {this.props.count} Всего
                        </span>                       
                    </div>                    
                </div> 
               
                {
                    (window.innerWidth > 1024 && (this.props.role === roleId.admin || this.props.role === roleId.coordinator)) && (
                        <div className="kt-subheader__toolbar">
                            <button
                                onClick={this.resetActivate}
                                className={cn({ 'btn btn-label-brand btn-bold': true, 'kt-spinner': this.props.editFilialLoader })}>
                                Сброс состояния
                            </button>
                            <button
                                onClick={this.resetTime}
                                className={cn({ 'btn btn-label-brand btn-bold': true, 'kt-spinner': this.props.editFilialLoader })}>
                                Сброс времени
                            </button>
                            {this.props.role === roleId.admin && (
                            <Link to="/handbook/filials/add" className="btn btn-label-brand btn-bold">
                                Добавить филиал
                            </Link>
                            )}
                        </div>
                    )
                }
                
            </div>
            </div>
        );
    }

    deleteFilial = (item) => {
        swal({
            title: "Удаление филиала",
            text: "Вы действительно хотите удалить филиал?",
            icon: "warning",
            dangerMode: true,
            buttons: ["Отмена", "Удалить"],
        }).then((willDelete) => {
            if (willDelete) {
                deleteFilial(item).then(result => {
                    if (result) {
                        this.props.fetchFilials();
                    } else {
                        this.props.setError({ status: 507 }, 'filials');
                    }
                })
            }
        });
    }

    activateFilial = (item) => {
        swal({
            title: `${item.stop ? 'Активация филиала' : 'Выключение филиала'}`,
            text: `Вы действительно хотите ${item.stop ? 'активировать филиал' : 'выключить филиал'}?`,
            icon: "warning",
            dangerMode: true,
            buttons: ["Отмена", "Подтвердить"],
        }).then((willDelete) => {
            if (willDelete) {
                activateFilial(item).then(result => {
                    if (result) {
                        this.props.fetchFilials();
                    } else {
                        this.props.setError({ status: 507 }, 'filials');
                    }
                })
            }
        });
    }

    render() {
        if (this.props.loading) {
            return (
                <div style={styles.loading}><Loader /></div>
            );
        }
        
        const actionsMobile =[
            { access: [roleId.coordinator, roleId.admin], name: 'Сброс состояния', icon: 'flaticon-reply', action: () => this.resetActivate()},
            { access: [roleId.coordinator, roleId.admin], name: 'Сброс времени', icon: 'flaticon-clock-1', action: () => this.resetTime()},
            { access: [roleId.admin], name: 'Добавить филиал', icon: 'flaticon2-plus', action: () => this.props.history.push('/handbook/filials/add')}
        ].filter(item => !item.access || item.access.includes(this.props.role));

        return (
            <Fragment>
                {actionsMobile.length > 0 && <HeadMobileActionMenu actions={actionsMobile}/>}
                { this.renderSubHeader() }
                <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid" style={window.innerWidth > 760 ? {} : { padding: '5px '}}>
                {(window.innerWidth > 1024 || this.props.UImobile.openFilters) && 
                    <div className="kt-portlet__body">
                        <div className="kt-form kt-form--label-right kt-margin-b-10">
                            <div style={{ width: 250 }}>
                                <Select
                                    isLoading={this.props.loadingCity}
                                    isDisabled={this.props.loadingCity}
                                    value={this.props.filters.city}
                                    closeMenuOnSelect={true}
                                    onChange={this.setFilialsFilters('city')}
                                    options={this.props.cities.map(item => ({
                                        value: item.id,
                                        label: item.name
                                    }))}
                                    placeholder="Город"
                                    noOptionsMessage={() => 'Нет городов'}
                                />
                            </div>
                        </div>
                    </div>
                }
                <Portlet fit={true}>
                    <ErrorContainer field="filials" style={{ margin: '15px' }} hasCloseButton={true}/>
                    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
                        <TableFilials 
                        activate={this.activateFilial} 
                        delete={this.deleteFilial} 
                        items={this.props.filials} 
                        role={this.props.role} 
                        setFilialEdit={this.props.setFilialEdit} 
                        updateFilial={this.props.updateFilial} 
                        fetchFilial={this.props.fetchFilial} 
                        filial={this.props.filial} 
                        fetchFilials={this.props.fetchFilials} 
                        edit={this.props.edit} 
                        addFilialsEdit={this.props.addFilialsEdit} 
                        changeFilialsEdit={this.props.changeFilialsEdit} 
                        editFilials={this.props.editFilials} 
                        removeFilialsEdit={this.props.removeFilialsEdit}
                        loadingGoods={this.props.loadingGoods}
                        goods={this.props.goods}
                        UImobile={this.props.UImobile}
                        />
                        {
                            this.props.filials.length > 0 && this.renderPagination()
                        }
                    </div>
                </Portlet>
            </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    const goodsSelector = state => state.good.list;
    const categoriesSelector = state => state.good.categories;

    const normalizeGoodsSelector = createSelector(
        goodsSelector,
        categoriesSelector,
        (goods, categories) => {
            const resultCategories = categories.reduce((data, item) => {
                data[item.id] = item.title;
                return data;
            }, {});

            const resultGoods = goods.reduce((data, item) => {
                if (data[item.category.id]) {
                    data[item.category.id].push({ value: item.id, label: item.name });
                } else {
                    data[item.category.id] = [{ value: item.id, label: item.name }];
                }
                return data;
            }, {});
            
            const result = Object.keys(resultGoods).map(key => {
                return ({
                    label: resultCategories[key],
                    options: resultGoods[key],
                });
            }).filter(item => item.label);

            return result;
        }
    );
    
    return ({
    filials: state.handbook.filials,
    filters: state.handbook.filters.filial,
    loading: state.loaders.filials,
    paging: state.handbook.paging.filial,
    count: state.handbook.count.filials,
    role: state.user.info.role.id,
    cities: state.handbook.cities,
    loadingCity: state.loaders.cities,
    filial: state.handbook.filial,
    editFilials: state.handbook.editFilials,
    loadingGoods: state.loaders.goods || state.loaders.category,
    goods: normalizeGoodsSelector(state),
    UImobile: state.UImobile
})
};

const mapDispatchToProps = {
    fetchCities: () => fetchCities(true),
    fetchFilials: () => fetchFilials(),
    setHandbookPaging: (field, paging) => setHandbookPaging(field, paging),
    setHandbookFilters: (field, filters) => setHandbookFilters(field, filters),
    setError: (code, field) => setError(code, field),
    setFilialEdit: (data) => setFilialEdit(data),
    updateFilial: (id, data) => updateFilial(id, data),
    fetchFilial: (id) => fetchFilial(id),
    updateFilials: (data) => updateFilialsTimeDelivery(data),
    addFilialsEdit: (data) => addFilialsEdit(data),
    changeFilialsEdit: (data) => changeFilialsEdit(data),
    removeFilialsEdit: (data) => removeFilialsEdit(data),
    fetchGoods: () => fetchGoods(),
    fetchCategories: () => fetchCategories(),
    setUIvisible: (visibleFiltersButton, visibleActionButton) => setUIvisible(visibleFiltersButton, visibleActionButton)
}

export default connect(mapStateToProps, mapDispatchToProps)(FilialList);