import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setStatFilters, resetStatFilters, fetchClientsStat, fetchNewClientsStat } from '../../action/statistics';
import { fetchCities } from '../../action/handbook';
import DateInput from '../utils/DateInput';
import moment from 'moment';
import { utils, writeFileXLSX } from 'xlsx';

class Clients extends Component {
    componentDidMount() {
       this.props.fetchCities();
    }

    setFilters = (field) => (data) => {
        const { filters } = this.props.clients;
        const updated = {
            ...filters,
            [field]: data
        }
        this.props.setStatFilters('clients', updated);
    }

    onChangeDateInput = (field) => (date) => {
        const { filters } = this.props.clients;

        const updated = {
            ...filters,
            [field]: date,
        };

        const isAfter = moment(updated.createdStartDate).isAfter(updated.createdEndDate)
        
        if (field === 'createdEndDate' && isAfter) {
            updated.createdStartDate = date
        }
        
        if (field === 'createdStartDate' && isAfter) {
            updated.createdEndDate = date
        }

        this.props.setStatFilters('clients', updated);
    }

    resetFilter = () => {
        this.props.resetStatFilters('clients');
        
    }

    onSearch = () => {
        this.props.fetchClientsStat();
    }

    onDownload = () => {
        this.props.fetchClientsStat()
        .then((res) => {
            if(!!res) {
            const clients = res.map(item => {
                return {
                    'Телефон': item.phone,
                    'ФИО': item.name,
                    'Город': item.city == null ? '-' : item.city.name == null ? '-' : item.city.name,
                    'Количество заказов': item.ordersCount,
                }
            })
            const ws = utils.json_to_sheet(clients);
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "Data");
            writeFileXLSX(wb, "Clients.xlsx");
            }
        }
        );
    }
    
    onDownloadNewClients = () => {
        this.props.fetchNewClientsStat()
        .then((res) => {
            if(!!res) {
            const clients = res.map(item => {
                return {
                    'id': item.id,
                    'Телефон': item.phone,
                    'ФИО': item.name,
                    'Город': item.city == null ? '-' : item.city,
                    'Дата регистрации': moment(item.created_at).format('DD.MM.YYYY HH:mm'),
                    'Количество заказов': +item.ordersCount,
                }
            })
            const ws = utils.json_to_sheet(clients);
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "Data");
            writeFileXLSX(wb, "New Clients.xlsx");
            }
        }
        );
    }

    isDisabled = () => {
        const { clients } = this.props
        const { filters } = clients;

        if (filters.createdStartDate && filters.createdEndDate) {
            return false;
        }
        return true;
    }

    onExport = (items) => () => {
        const clients = items.map(item => {
            return {
                'Телефон': item.phone,
                'ФИО': item.name,
                'Город': item.city == null ? '-' : item.city.name == null ? '-' : item.city.name,
                'Количество заказов': item.ordersCount,
            }
        })
        const ws = utils.json_to_sheet(clients);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Data");
        writeFileXLSX(wb, "Clients.xlsx");
    }
    
    render() {
        const { clients } = this.props
        const { loading, filters } = clients;
        return (
            <>
                <div className="kt-portlet">
                    <div className="kt-portlet__head" style={{ flexDirection: 'column', gap: '10px' }}>
                        <div className="kt-portlet__head-label">
                            <h3 className="kt-portlet__head-title">
                                Настройки
                            </h3>
                        </div>
                        <div className="kt-portlet__head-toolbar" style={{ flexWrap: 'wrap', gap: '10px' }}>
                            <div className="dateBlock">
                                <DateInput
                                    disabled={loading}
                                    placeholderText="Дата от"
                                    selected={filters.createdStartDate}
                                    onChange={this.onChangeDateInput('createdStartDate')}
                                    maxDate={moment().toDate()}
                                />
                                <div>-</div>
                                <DateInput
                                    disabled={loading}
                                    placeholderText="Дата до"
                                    selected={filters.createdEndDate}
                                    onChange={this.onChangeDateInput('createdEndDate')}
                                    maxDate={moment().toDate()}
                                    required={filters.createdStartDate}
                            />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="kt-portlet">
                    <div className="kt-portlet__head">
                        <div className="kt-portlet__head-label">
                            <h3 className="kt-portlet__head-title">
                                Клиенты - количество заказов
                            </h3>
                        </div>
                        <div className="kt-portlet__head-toolbar">
                            <div className="dateBlock" style={{ marginRight: 10 }}>
                                <button onClick={this.onDownload} className="btn btn-dark btn-icon-sm">Экспорт</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="kt-portlet">
                    <div className="kt-portlet__head">
                        <div className="kt-portlet__head-label">
                            <h3 className="kt-portlet__head-title">
                                Клиенты - новые
                            </h3>
                        </div>
                        <div className="kt-portlet__head-toolbar">
                            <div className="dateBlock" style={{ marginRight: 10 }}>
                                <button onClick={this.onDownloadNewClients} className="btn btn-dark btn-icon-sm">Экспорт</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>         
        );
    }
}

const mapStateToProps = state => {
    return ({
        clients: state.statistics.clients,
        cities: state.handbook.cities,
        loadingCity: state.loaders.cities,
    });
};

const mapDispatchToProps = {
    fetchClientsStat: () => fetchClientsStat(),
    fetchNewClientsStat: () => fetchNewClientsStat(),
    fetchCities: () => fetchCities(true),
    setStatFilters: (name, filters) => setStatFilters(name, filters),
    resetStatFilters: (name) => resetStatFilters(name),
}

export default connect(mapStateToProps, mapDispatchToProps)(Clients);