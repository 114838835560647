import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { fetchPhoneCodes, setPagingPhoneCodes } from '../../../action';
import { fromStore } from '../../../selectors';

export const PaginationList = () => {
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(0);
    const codes = useSelector(fromStore.countPhoneCodesSelector);
    const paging = useSelector(fromStore.pagingPhoneCodesSelector);
    const selectedCount = codes;
    const pages = Math.ceil(selectedCount / +paging.limit);

    const handlePageClick = (data) => {
        setCurrentPage(data.selected);
        const updatedPaging = {
            ...paging,
            start: data.selected * paging.limit
        };
        dispatch(setPagingPhoneCodes(updatedPaging));
        dispatch(fetchPhoneCodes());
    };

    const setPageLimit = (e) => {
        e.preventDefault();
        setCurrentPage(0);
        dispatch(setPagingPhoneCodes({
            start: 0, limit: e.target.value
        }));
        dispatch(fetchPhoneCodes());
    };

    useEffect(() => {
        return () => {
            setCurrentPage(0);
            const data = { ...paging, start: 0 };
            dispatch(setPagingPhoneCodes(data));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const chekMarginPagesDisplayed = () => {
        const userDisplayWidth = window.innerWidth;

        if(userDisplayWidth < 400)
            return 1;

        if(userDisplayWidth < 550)
            return 2;

        if(userDisplayWidth < 850)
            return 3;

        if(userDisplayWidth < 1100)
            return 4;

        if(userDisplayWidth < 1250)
            return 5;

        return 8;
    }

    const chekPageRangeDisplayed = () => {
        const userDisplayWidth = window.innerWidth;

        if(userDisplayWidth < 550)
            return 2;

        if(userDisplayWidth < 850)
            return 3;

        if(userDisplayWidth < 1100)
            return 4;

        return 5;
    }

    return (
        <div style={{ marginBottom: '20px' }} className="kt-datatable__pager kt-datatable--paging-loaded">
            <ReactPaginate
                previousLabel={<i className="flaticon2-back"></i>}
                nextLabel={<i className="flaticon2-next"></i>}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pages}
                marginPagesDisplayed={chekMarginPagesDisplayed()}
                pageRangeDisplayed={chekPageRangeDisplayed()}
                forcePage={currentPage}
                onPageChange={handlePageClick}
                containerClassName={'kt-datatable__pager-nav'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                pageLinkClassName="kt-datatable__pager-link kt-datatable__pager-link-number"
                previousLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--prev"
                nextLinkClassName="kt-datatable__pager-link kt-datatable__pager-link--next"
                activeLinkClassName="kt-datatable__pager-link--active"
                disabledClassName="kt-datatable__pager-link--disabled"
            />
                <div className="kt-datatable__pager-info">
                    <div className="kt-datatable__pager-size">
                        <select disabled={!selectedCount} onChange={setPageLimit} value={paging.limit} className="form-control">
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        
                    </div>
                    {
                        paging.start + paging.limit >= selectedCount ? (
                            <span className="kt-datatable__pager-detail">Показано {paging.start + 1} - {+selectedCount} из {selectedCount}</span>
                        ) : (
                            <span className="kt-datatable__pager-detail">Показано {paging.start + 1} - {+paging.start + +paging.limit} из {selectedCount}</span>
                        )
                    }
                   
                </div>
        </div>

    );
}