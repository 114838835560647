import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Input from '../components/utils/Input';
import Content from '../components/layout/Content';
import Switch from '../components/utils/Switch';
import Loader from '../components/utils/Loader';
import { fetchSettings, setEditedSettings, updateSettings } from '../action/app';
import { isEqual } from 'lodash';
import ErrorContainer from '../components/utils/ErrorContainer';
import { roleId } from '../constants/app';
import { setUIvisible } from '../action/UImobile';



const rangeDate = {
    'month-1': '1 месяц',
    'month-2': '2 месяца',
    'month-3': '3 месяца'
};

const zoneModeData = {
    'execute': 'Отправить в филиал Сайт',
    'abort': 'Не создавать заказ',
};

const styles = {
    loading: {
        minHeight: '500px',
        display: 'flex',
        justifyContent: 'center'
    }
}

class Settings extends Component {
    componentDidMount() {
        this.props.fetchSettings();
        this.props.setUIvisible(false, false);
    }

    state = {
        sale: false,
        persent: false
    }
    
    renderSubHeader() {
        return (
            <div className="kt-subheader not-mt kt-grid__item">
                <div className="kt-container  kt-container--fluid ">
                <div className="kt-subheader__main">
                    <h3 className="kt-subheader__title">Настройки</h3>
                    <span className="kt-subheader__separator kt-subheader__separator--v"></span>
                    <div className="kt-subheader__group" id="kt_subheader_search">
                        <span className="kt-subheader__desc">
                            
                        </span>
                    </div>
                </div>        
                <div className="kt-subheader__toolbar">
                    <button disabled={!this.isValidSale()} onClick={this.onSubmit} className="btn btn-primary">Сохранить настройки</button>
                </div>
            </div>
            </div>
        );
    }

    onChangeSettings = (field) => (value) => {
        this.props.setEditedSettings(field, value);
    }

    onChangeBonusSettings = (field) => (value) => {
        const { edited } = this.props.settings;
        const updated = {...edited.bonusConfigs};
        updated[field] = value;

        this.props.setEditedSettings('bonusConfigs', updated);
    }

    onChangeCategoriesSettings = (field) => (value) => {
        const { edited } = this.props.settings;
        const categories = edited.categories || {};
        const updated = {...categories};
        updated[field] = value;

        this.props.setEditedSettings('categories', updated);
    }

    onSubmit = (e) => {
        e.preventDefault();
        this.props.updateSettings(this.props.settings.edited);
    }

    isValidSale() {
        const { edited, init } = this.props.settings;
        
        let result = true;

        if (isEqual(edited, init)) {
            result = false;
        }

        if (!edited.saleFirstOrderValue) result = false;
        if (!edited.categories) return false;
        if (!edited.categories.pizza || !edited.categories.wok || !edited.categories.mainWok || !edited.categories.souceWok || !edited.categories.meatWok || !edited.categories.toppingWok) return false;
        if (edited.bonusConfigs.level1Active && !edited.bonusConfigs.level1Value) return false;
        if (edited.bonusConfigs.level2Active && !edited.bonusConfigs.level2Value) return false;
        if (edited.bonusConfigs.level3Active && !edited.bonusConfigs.level3Value) return false;
        if (!edited.bonusOrderPersent || edited.bonusOrderPersent < 10 || edited.bonusOrderPersent > 100) return false;

        return result;
    }

    renderSale() {
        const { edited } = this.props.settings;

        return (
            <div className="kt-portlet">
                <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">
                            Скидка на первый заказ
                        </h3>
                    </div>

                    <div className="kt-portlet__head-toolbar">
                        <div style={{ marginTop: '13px' }}>
                            <Switch onChange={(value) => this.onChangeSettings('saleFirstOrderActive')(value)} checked={edited.saleFirstOrderActive}/>
                        </div>
                    </div>
                    
                </div>
            
                
                    <div className="kt-portlet__body">
                        <Input 
                            label="Размер скидки"
                            value={edited.saleFirstOrderValue}
                            onChange={(e) => this.onChangeSettings('saleFirstOrderValue')(+e.target.value)}
                            type="number"
                        />
                        <div className="form-group row">
                            
                            <label className="col-9 col-form-label">Процент</label>
                            <div className="col-3">
                                <Switch onChange={(value) => this.onChangeSettings('saleFirstPersent')(value)} checked={edited.saleFirstPersent}/>
                            </div>
                        </div>
                        
                    </div>
                    
               	
            </div>
                        
        );
    }

    renderVersion() {
        const { edited } = this.props.settings;
  
        return (
            <div className="kt-portlet">
                <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">
                            Уведомление обновления приложения
                        </h3>
                    </div>
                    <div className="kt-portlet__head-toolbar">
                        <div style={{ marginTop: '13px' }}>
                            <Switch onChange={(value) => this.onChangeSettings('showVersion')(value)} checked={edited.showVersion}/>
                        </div>
                    </div>
                </div>
                <div className="kt-portlet__body">
                    <Input 
                        label="Текущая версия приложения"
                        value={edited.version}
                        onChange={(e) => this.onChangeSettings('version')(e.target.value)}
                    />
                </div>
            </div>                        
        );
    }
    
    renderLog() {
        const { edited } = this.props.settings;
  
        return (
            <>
                { this.props.currentUser.role.id === roleId.admin && (
                <div className="kt-portlet">
                    <div className="kt-portlet__head">
                        <div className="kt-portlet__head-label">
                            <h3 className="kt-portlet__head-title">
                                Логирование
                            </h3>
                        </div>
                        <div className="kt-portlet__head-toolbar">
                            <div style={{ marginTop: '13px' }}>
                                <Switch onChange={(value) => this.onChangeSettings('cashbackLogs')(value)} checked={edited.cashbackLogs}/>
                            </div>
                        </div>
                    </div>
                </div>   
                )}                        
            </>
            );
        }
        
        renderPointsLogs() {
            const { edited } = this.props.settings;
        
            return (
                <>
                    { this.props.currentUser.role.id === roleId.admin && (
                    <div className="kt-portlet">
                        <div className="kt-portlet__head">
                            <div className="kt-portlet__head-label">
                                <h3 className="kt-portlet__head-title">
                                    Логирование работы филиалов
                                </h3>
                            </div>
                            <div className="kt-portlet__head-toolbar">
                                <div style={{ marginTop: '13px' }}>
                                    <Switch onChange={(value) => this.onChangeSettings('pointsLogs')(value)} checked={edited.pointsLogs}/>
                                </div>
                            </div>
                        </div>
                    </div>   
                    )}                        
                </>         
        );
    }

    renderWokDescr() {
        const { edited } = this.props.settings;

        return (
            <div className="kt-portlet">
                <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">
                            Настройка описания вока
                        </h3>
                    </div>
                </div>
                <div className="kt-portlet__body">
                    <Input 
                        label="Описание вока"
                        value={edited.wokDescr}
                        onChange={(e) => this.onChangeSettings('wokDescr')(e.target.value)}
                    />
                </div>
            </div>
                        
        );
    }

    renderOrder() {
        const { edited } = this.props.settings;

        return (
            <div className="kt-portlet">
                <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">
                            Настройка заказов
                        </h3>
                    </div>
                </div>
            
                
                    <div className="kt-portlet__body">
                    
                   
                        <div className="row" style={{ marginBottom: 10 }}>
                            <label className="col-xl-12 col-lg-12 col-md-12 col-form-label">Постоянный клиент</label>
                            <div className="col-xl-12 col-lg-12 col-md-12">
                                <div style={{ display: 'flex' }}>
                                    <Input
                                        disableGroup
                                        value={edited.loyalOrderCount}
                                        onChange={(e) => this.onChangeSettings('loyalOrderCount')(+e.target.value)}
                                        type="number"
                                    />
                                    <div style={{ padding: 10, whiteSpace: 'nowrap' }}>заказов за</div>
                                    <select onChange={(e) => this.onChangeSettings('loyalRangeDate')(e.target.value)} value={edited.loyalRangeDate} className="form-control">
                                        {
                                            Object.keys(rangeDate).map((key, index) => (
                                                <option key={key} value={key}>{rangeDate[key]}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <span className="form-text text-muted" style={{ marginTop: 5 }}>0 - выключить</span>
                            </div>
                        </div>

                        <div className="kt-separator kt-separator--border-dashed kt-separator--space-md"></div>

                        <div className="form-group row">
                            <label className="col-9 col-form-label">Определение зоны доставки</label>
                            <div className="col-3">
                                <Switch onChange={(value) => this.onChangeSettings('zone')(value)} checked={edited.zone}/>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-9 col-form-label">Определение зоны только для лояльных пользователей</label>
                            <div className="col-3">
                                <Switch onChange={(value) => this.onChangeSettings('onlyLoyal')(value)} checked={edited.onlyLoyal}/>
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-12 col-form-label">При неопределении координат адреса</label>
                            <div className="col-12">
                                <select onChange={(e) => this.onChangeSettings('zoneMode')(e.target.value)} value={edited.zoneMode} className="form-control">
                                        {
                                            Object.keys(zoneModeData).map((key, index) => (
                                                <option key={key} value={key}>{zoneModeData[key]}</option>
                                            ))
                                        }
                                    </select>
                            </div>
                        </div>

                        <div className="kt-separator kt-separator--border-dashed kt-separator--space-md"></div>

                        <div className="form-group row">
                            <label className="col-9 col-form-label">Отправлять Email</label>
                            <div className="col-3">
                                <Switch onChange={(value) => this.onChangeSettings('sendEmail')(value)} checked={edited.sendEmail}/>
                            </div>
                        </div>
                        
                    </div>
                    
               	
            </div>
                        
        );
    }

    renderLevels() {
        const { bonusConfigs } = this.props.settings.edited;

        return (
            <tbody>
                <tr>
                    <td style={{ width: '33.3%' }}>Уровень 1 - клиент</td>
                    <td style={{ width: '33.3%' }}>
                        <div>
                            <Input 
                                disableGroup={true}
                                value={bonusConfigs.level1Value || ''}
                                onChange={(e) => this.onChangeBonusSettings('level1Value')(+e.target.value)}
                                type="number"
                            />
                        </div>
                    
                    </td>
                    <td >
                        <Switch nomargin={true} onChange={(value) => this.onChangeBonusSettings('level1Active')(value)} checked={bonusConfigs.level1Active}/>
                    </td>
                </tr>
                <tr style={{ width: '33.3%' }}>
                    <td style={{ width: '33.3%' }}>Уровень 2 - рекомендатор клиента</td>
                    <td style={{ width: '33.3%' }}>
                        <div>
                            <Input 
                                disableGroup={true}
                                value={bonusConfigs.level2Value || ''}
                                onChange={(e) => this.onChangeBonusSettings('level2Value')(+e.target.value)}
                                type="number"
                            />
                        </div>
                    
                    </td>
                    <td style={{ width: '33.3%' }}>
                        <Switch nomargin={true} onChange={(value) => this.onChangeBonusSettings('level2Active')(value)} checked={bonusConfigs.level2Active}/>
                    </td>
                </tr>
                <tr>
                    <td style={{ width: '33.3%' }}>Уровень 3 - рекомендатор рекомендатора клиента</td>
                    <td style={{ width: '33.3%' }}>
                        <div>
                            <Input 
                                disableGroup={true}
                                value={bonusConfigs.level3Value || ''}
                                onChange={(e) => this.onChangeBonusSettings('level3Value')(+e.target.value)}
                                type="number"
                            />
                        </div>
                    
                    </td>
                    <td style={{ width: '33.3%' }}>
                        <Switch nomargin={true} onChange={(value) => this.onChangeBonusSettings('level3Active')(value)} checked={bonusConfigs.level3Active}/>
                    </td>
                </tr>
            </tbody>
        );  
    }


    renderCashBack() {
        return (
            
            <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--loaded">
                <div className="kt-widget11">
                    <div className="table-responsive">					 
                        <table className="table">
                            <thead>
                                <tr style={{ borderBottom: '1px solid #eff0f6' }}>
                                    <td style={{ width: '33.3%', paddingBottom: 10 }}>Уровень</td>
                                    <td style={{ width: '33.3%', paddingBottom: 10 }}>Размер скидки (% от заказа)</td>
                                    <td style={{ width: '33.3%', paddingBottom: 10 }}>Активен</td>
                                </tr>
                            </thead>
                            {this.renderLevels()}  
                        </table>
                    </div>
                </div>
            </div>
        );
    }

    renderBonus() {
        const { edited } = this.props.settings;

        return (
            <div className="kt-portlet">
                <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">
                            Бонусная программа
                        </h3>
                    </div>

                    <div className="kt-portlet__head-toolbar">
                        <div style={{ marginTop: '13px' }}>
                            <Switch onChange={(value) => this.onChangeSettings('bonusProgramm')(value)} checked={edited.bonusProgramm}/>
                        </div>
                    </div>
                    
                </div>
            
                            <div className="kt-portlet__body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <Input
                                            required={true}
                                            label="Бонус за регистрацию"
                                            value={edited.bonusConfigs.registration}
                                            onChange={(e) => this.onChangeBonusSettings('registration')(+e.target.value)}
                                            type="number"
                                            description="0 - выключить"
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <Input 
                                            label="Бонус за 'приведи друга'"
                                            value={edited.bonusConfigs.friend}
                                            onChange={(e) => this.onChangeBonusSettings('friend')(+e.target.value)}
                                            type="number"
                                            description="0 - выключить"
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <Input 
                                            label="Бонус за регистрацию по промокоду"
                                            value={edited.bonusConfigs.inviter}
                                            onChange={(e) => this.onChangeBonusSettings('inviter')(+e.target.value)}
                                            type="number"
                                            description="0 - выключить"
                                        />
                                    </div>
                                 </div>
                                 <div className="kt-separator kt-separator--border-dashed kt-separator--space-sm"></div>
                                 <div className="row" style={{ marginTop: 20 }}>
                                    <div className="col-md-4">
                                        <Input
                                            required={true}
                                            label="Процент от суммы заказа"
                                            value={edited.bonusOrderPersent}
                                            onChange={(e) => this.onChangeSettings('bonusOrderPersent')(+e.target.value)}
                                            type="number"
                                            description="10% - минимум, 100% - максимум"
                                        />
                                    </div>
                                    </div>
                                 <div className="kt-separator kt-separator--border-dashed kt-separator--space-sm"></div>
                                 <div style={{ display: 'flex', alignItems: 'center', fontSize: '1.2rem', fontWeight: 500, color: '#48465b', marginTop: 15, marginBottom: 25 }}>
                                    <div style={{ marginRight: 15 }} className="kt-widget__icon">
                                        <i style={{ fontSize: '2.2em', color: '#0abb87' }} className="flaticon-network"></i>
                                    </div>
                                    Кэшбэк
                                 </div>
                                 {this.renderCashBack()}
                            </div>
            
	
            </div>
        );
    }

    renderCategories() {
        const { edited } = this.props.settings;

        return (
            <div className="kt-portlet">
                <div className="kt-portlet__head">
                    <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">
                            Настройка категорий
                        </h3>
                    </div>
                </div>
            
                            <div className="kt-portlet__body">
                                <div className="row">
                                    <div className="col-md-4">
                                        <Input
                                            required={true}
                                            label="ID категории Пицца"
                                            value={edited.categories ? edited.categories.pizza : ''}
                                            onChange={(e) => this.onChangeCategoriesSettings('pizza')(+e.target.value)}
                                            type="number"
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <Input
                                            required={true}
                                            label="ID категории Wok"
                                            value={edited.categories ? edited.categories.wok : ''}
                                            onChange={(e) => this.onChangeCategoriesSettings('wok')(+e.target.value)}
                                            type="number"
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <Input
                                            required={true}
                                            label="ID категории Основа wok"
                                            value={edited.categories ? edited.categories.mainWok : ''}
                                            onChange={(e) => this.onChangeCategoriesSettings('mainWok')(+e.target.value)}
                                            type="number"
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <Input
                                            required={true}
                                            label="ID категории Соусы wok"
                                            value={edited.categories ? edited.categories.souceWok : ''}
                                            onChange={(e) => this.onChangeCategoriesSettings('souceWok')(+e.target.value)}
                                            type="number"
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <Input
                                            required={true}
                                            label="ID категории Мясо wok"
                                            value={edited.categories ? edited.categories.meatWok : ''}
                                            onChange={(e) => this.onChangeCategoriesSettings('meatWok')(+e.target.value)}
                                            type="number"
                                        />
                                    </div>
                                    <div className="col-md-4">
                                        <Input
                                            required={true}
                                            label="ID категории Топпинги wok"
                                            value={edited.categories ? edited.categories.toppingWok : ''}
                                            onChange={(e) => this.onChangeCategoriesSettings('toppingWok')(+e.target.value)}
                                            type="number"
                                        />
                                    </div>
                                 </div>
                            </div>
            
	
            </div>
        );
    }

    render() {
        if (this.props.loading) {
            return (
                <div style={styles.loading}><Loader /></div>
            );
        }
        
        return (
            <Fragment>
                <Content>
                { this.renderSubHeader() }
                <div className="kt-container kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                        <ErrorContainer field="settings" container={true} hasCloseButton={true}/>
                        <div className="row">
                            <div className="col-xl-3 col-sm-12 col-md-4">
                                {this.renderSale()}
                                {this.renderOrder()}
                                {this.renderWokDescr()}
                                {this.renderVersion()}
                                {this.renderLog()}
                                {this.renderPointsLogs()}
                            </div>
                            <div className="col-xl-9 col-sm-12 col-md-8">
                                {this.renderBonus()}
                                {this.renderCategories()}
                            </div>
                        </div>
                </div>
                </Content>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings,
    loading: state.loaders.settings,
    currentUser: state.user.info,
});

const mapDispatchToProps = {
    fetchSettings: () => fetchSettings(),
    setEditedSettings: (field, value) => setEditedSettings(field, value),
    updateSettings: (data) => updateSettings(data),
    setUIvisible: (visibleFiltersButton, visibleActionButton) => setUIvisible(visibleFiltersButton, visibleActionButton)
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);