import React, { Component } from 'react';
import cn from 'classnames';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { setUImobile } from '../../action/UImobile';
const actionMenu = document.body;
  class Modal extends Component {
    constructor(props) {
      super(props);
      this.el = document.createElement('div');
    }
  
    componentDidMount() {
      actionMenu.appendChild(this.el);
      document.addEventListener('click', this.closeModal);
    }
  
    componentWillUnmount() {
      actionMenu.removeChild(this.el);
      document.removeEventListener('click', this.closeModal);
    }
    
    closeModal = (evt) => {
        this.props.close();
    }
    
    render() {
      // Use a portal to render the children into the element
      return ReactDOM.createPortal(
        // Any valid React child: JSX, strings, arrays, etc.
        this.props.children,
        // A DOM element
        this.el
      );
    }
  }
  
class HeadMobileActionMenu extends Component {
    onClick = (e) => {
        e.preventDefault();
        
        let targetElement = e.target; 
        while (!targetElement.classList.contains('dropdown')) {
            targetElement = targetElement.parentNode;
        }
        this.mod = targetElement;
    }

    closeModal = () => {
        if(this.props.UImobile.openAction)
            this.props.setUImobile('openAction')
    }

    onAction = (action) => (e) => {
        e.preventDefault();
        action();
        if(this.props.UImobile.openAction)
            this.props.setUImobile('openAction')
    }

    render() {
        if(this.props.UImobile.openAction)
            return (
                <span style={{ overflow: 'visible', position: 'relative', width: '80px' }}>
                    <div className={cn({ dropdown: true, show: this.props.UImobile.openAction })}>                    
                                <Modal close={this.closeModal}>
                                    <div ref={node => this.el = node}
                                        className="dropdown-menu hidden_desktop dark dropdown-menu-right"
                                        style={{ width: '200px', display: 'block', position: 'fixed', top: '55px', right: '5px'}}>
                                        <ul className="kt-nav">
                                            {
                                                this.props.actions.map((item, index) => (
                                                    <li key={index} className="kt-nav__item dark">
                                                        <a onClick={this.onAction(item.action)} className="kt-nav__link" href="/">
                                                            <i className={`kt-nav__link-icon ${item.icon}`}></i>
                                                            <span className="kt-nav__link-text dark">{item.name}</span>
                                                        </a>
                                                    </li>
                                                ))
                                            }                                    
                                        </ul>
                                    </div>
                                </Modal>
                    </div>
                </span>
            );

        return(
            <></>
        )
    }
}

const mapStateToProps = state => ({
    UImobile: state.UImobile
});

const mapDispatchToProps = {
    setUImobile: (field) => setUImobile(field)
}

export default connect(mapStateToProps, mapDispatchToProps)(HeadMobileActionMenu);