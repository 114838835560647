import React from 'react';
import { useSelector } from 'react-redux';
import { fromStore } from '../../../selectors';
import cn from 'classnames';
import { withRouter } from 'react-router-dom';
import { getImageUrl } from '../../../utils/common';
import { useDispatch } from 'react-redux';
import { deleteGood, setActiveModalAction, updateGood } from '../../../action';
import swal from 'sweetalert';
import { MEASURES } from '../constants';
import ActionMenu from "../../../components/utils/ActionMenu";

const Item = ({ item, history, UImobile  }) => {
    const dispatch = useDispatch();
    const loaderUpdateGood = useSelector(fromStore.loaderUpdateGoodSelector);
    const loaderDeleteGood = useSelector(fromStore.loaderDeleteGoodSelector);
    const loaderUpdate = loaderUpdateGood === item.id;
    const loadertDelete = loaderDeleteGood === item.id;

    const openDetail = () => {
        history.push(`/good/${item.id}`);
    };

    const onOpenModalGood = (id) => {
        const goodId = id || 'new';
        dispatch(setActiveModalAction({ field: 'good', value: goodId }));
    }

    const onToogleActiveGood = () => {
        const data = { active: !item.active };
        dispatch(updateGood(item.id, data))
    };

    const onDeleteGood = () => {
        swal({
            title: `Удаление товара`,
            text: `Вы действительно хотите удалить ${item.name}?`,
            icon: "warning",
            dangerMode: true,
            buttons: ["Отмена", "Удалить"],
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(deleteGood(item.id));
            }
        });
    }

    const actions = [
        { name: 'Просмотр',  icon: 'la la-eye', action: () => openDetail()},
        { name: 'Редактировать', icon: 'la la-edit', action: () => onOpenModalGood(item.id)},
        { name: 'Удалить', icon: 'la la-trash', action: () => onDeleteGood()}
    ];    

    if(window.innerWidth > 730)
        return (
            <tr key={`${item.id}_good`} className="kt-datatable__row" onDoubleClick={openDetail}>
                <td className={`kt-datatable__cell${UImobile.openSidebar ? ' openSidebar' : ''} good_id`}><span>{item.id}</span></td>
                {window.innerWidth > 830 && <td className={`kt-datatable__cell${UImobile.openSidebar ? ' openSidebar' : ''} good_preview`}>
                    <img width="50px" alt={'NaN'} src={getImageUrl(item.image)} />
                </td>}
                <td className={`kt-datatable__cell${UImobile.openSidebar ? ' openSidebar' : ''} good_name`}><span>{item.name}</span></td>
                <td className={`kt-datatable__cell${UImobile.openSidebar ? ' openSidebar' : ''} good_info`}><span>{`${item.price} ₽`}</span></td>
                <td className={`kt-datatable__cell${UImobile.openSidebar ? ' openSidebar' : ''} good_info`}><span>{item.oldPrice ? `${item.oldPrice} ₸` : '-'}</span></td>
                <td className={`kt-datatable__cell${UImobile.openSidebar ? ' openSidebar' : ''} good_info article`}><span>{item.article || '-'}</span></td>
                <td className={`kt-datatable__cell${UImobile.openSidebar ? ' openSidebar' : ''} good_info`}>
                    <span>{item.weight} {MEASURES[item.measure]}</span>
                </td>
                <td align='right' className={`kt-datatable__cell${UImobile.openSidebar ? ' openSidebar' : ''} table_action`}>
                    <div style={{ width: '100%', height: '100%', display: 'flex', gap: '5px' }}>
                        {!loadertDelete && !loaderUpdate && <ActionMenu actions={actions}/>} 
                        {(loadertDelete || loaderUpdate) && <span style={{overflow: 'visible', position: 'relative', width: '80px'}}><button className='btn btn-sm btn-clean btn-icon btn-icon-md kt-spinner kt-spinner--center kt-spinner--sm kt-spinner--danger'></button></span> }
                        <button
                            onClick={onToogleActiveGood}
                            data-tip={item.active ? 'Выключить' : 'Включить'}
                            disabled={loaderUpdate || loadertDelete}
                            className={cn("btn btn-sm btn-icon btn-icon-md", {
                                'btn-danger': !item.active,
                                'btn-success': item.active,
                                'kt-spinner kt-spinner--center kt-spinner--sm kt-spinner--light': loaderUpdate
                            })}>
                            {!loaderUpdate && <i className="la la-power-off"></i>}
                        </button> 
                    </div>     
                </td>
            </tr>
        );

    return (
        <tr key={`${item.id}_good`} className="kt-datatable__row" onDoubleClick={openDetail}>
            <div className='goods_list_mobile main_row'>
                <div className='goods_list_mobile row'>                    
                    <div className="goods_list_mobile good_info"><span>{'ID: '}<span style={{color: '#000'}}>{item.id}</span></span></div>
                    <div className="goods_list_mobile good_name"><span style={{color: '#000'}}>{item.name}</span></div>                   
                </div>
                {window.innerWidth > 430 
                ?
                <div className='goods_list_mobile row'  style={{justifyContent: 'space-between', gap: 0}}>                    
                        <div className='mobile_div_flex gap5px'>
                            <div className="goods_list_mobile good_info"><span>{'Цена: '}<span style={{color: '#000'}}>{`${item.price} ₸`}</span></span></div>
                            <div className="goods_list_mobile good_info"><span>{'Старая цена: '}<span style={{color: '#000'}}>{`${item.oldPrice} ₸` || '-'}</span></span></div>
                        </div>
                        <div align='right' className="goods_list_mobile good_action">
                            <div  className='mobile_div_flex gap5px' style={{width: '100%', height: '100%' }}>
                                {!loadertDelete && !loaderUpdate && <ActionMenu actions={actions}/>} 
                                {(loadertDelete || loaderUpdate) && <span style={{overflow: 'visible', position: 'relative', width: '80px'}}><button className='btn btn-sm btn-clean btn-icon btn-icon-md kt-spinner kt-spinner--center kt-spinner--sm kt-spinner--danger'></button></span> }
                                <button
                                    onClick={onToogleActiveGood}
                                    data-tip={item.active ? 'Выключить' : 'Включить'}
                                    disabled={loaderUpdate || loadertDelete}
                                    className={cn("btn btn-sm btn-icon btn-icon-md", {
                                        'btn-danger': !item.active,
                                        'btn-success': item.active,
                                        'kt-spinner kt-spinner--center kt-spinner--sm kt-spinner--light': loaderUpdate
                                    })}>
                                    {!loaderUpdate && <i className="la la-power-off"></i>}
                                </button> 
                            </div>     
                        </div>           
                    </div>         
                    :
                    <div className='goods_list_mobile row'>
                        <div className="goods_list_mobile good_info"><span>{'Цена: '}<span style={{color: '#000'}}>{`${item.price} ₸`}</span></span></div>
                        <div className="goods_list_mobile good_info"><span>{'Старая цена: '}<span style={{color: '#000'}}>{`${item.oldPrice} ₸` || '-'}</span></span></div>
                    </div>
                    }
                
                <div className='goods_list_mobile row'>
                    <div className="goods_list_mobile good_info"><span>{'Артикул: '}<span style={{color: '#000'}}>{item.article || '-'}</span></span></div>
                    <div className="goods_list_mobile good_info">
                        <span>{'Вес/объем: '}<span style={{color: '#000'}}>{item.weight} {MEASURES[item.measure]}</span></span>
                    </div>
                </div>
                {window.innerWidth <= 430 && 
                <div className='goods_list_mobile row'>
                    <div className="goods_list_mobile good_info"></div>
                    <div align='right' className="goods_list_mobile good_action">
                        <div style={{width: '100%', height: '100%', display: 'flex', gap: '5px' }}>
                            {!loadertDelete && !loaderUpdate && <ActionMenu actions={actions}/>} 
                            {(loadertDelete || loaderUpdate) && <span style={{overflow: 'visible', position: 'relative', width: '80px'}}><button className='btn btn-sm btn-clean btn-icon btn-icon-md kt-spinner kt-spinner--center kt-spinner--sm kt-spinner--danger'></button></span> }
                            <button
                                onClick={onToogleActiveGood}
                                data-tip={item.active ? 'Выключить' : 'Включить'}
                                disabled={loaderUpdate || loadertDelete}
                                className={cn("btn btn-sm btn-icon btn-icon-md", {
                                    'btn-danger': !item.active,
                                    'btn-success': item.active,
                                    'kt-spinner kt-spinner--center kt-spinner--sm kt-spinner--light': loaderUpdate
                                })}>
                                {!loaderUpdate && <i className="la la-power-off"></i>}
                            </button> 
                        </div>     
                    </div>
                </div>
                }
            </div>
        </tr>
    );
}

export const ItemRow = withRouter(Item);