import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loader from '../utils/Loader';
import { fetchReffersStat, setStatFilters, resetStatFilters } from '../../action/statistics';
import { fetchCities } from '../../action/handbook';
import Select from 'react-select';
import DateInput from '../utils/DateInput';
import moment from 'moment';

const styles = {
    loading: {
        minHeight: '200px',
        display: 'flex',
        justifyContent: 'center'
    }
}

class Refferers extends Component {
    componentDidMount() {
       this.props.fetchReffersStat();
       this.props.fetchCities();
    }

    setFilters = (field) => (data) => {
        const { filters } = this.props.refferers;
        const updated = {
            ...filters,
            [field]: data
        }
        this.props.setStatFilters('refferers', updated);
        this.props.fetchReffersStat();
    }

    onChangeDateInput = (field) => (date) => {
        const { filters } = this.props.refferers;

        const updated = {
            ...filters,
            [field]: date,
        };

        if (field === 'createdStartDate') {
            updated.createdEndDate = moment(date).add({ days: 30 }).toDate()
        }

        this.props.setStatFilters('refferers', updated);
    }

    resetFilter = () => {
        this.props.resetStatFilters('refferers');
        this.props.fetchReffersStat();
    }

    onSearch = () => {
        this.props.fetchReffersStat();
    }

    render() {
        const { refferers } = this.props
        const { result, loading } = refferers;

        return (
            <div className="kt-portlet">
                <div className="kt-portlet__head"  style={{ flexDirection: 'column', gap: '10px' }}>
                    <div className="kt-portlet__head-label">
                        <h3 className="kt-portlet__head-title">
                            Рефельная программа
                        </h3>
                    </div>
                    <div className="kt-portlet__head-toolbar" style={{ flexWrap: 'wrap', gap: '10px' }}>
                        <div className="dateBlock">
                            <DateInput
                                disabled={loading}
                                placeholderText="Дата от"
                                selected={this.props.refferers.filters.createdStartDate}
                                onChange={this.onChangeDateInput('createdStartDate')}
                                maxDate={moment().toDate()}
                            />
                            <div>-</div>
                            <DateInput
                                disabled={loading}
                                placeholderText="Дата до"
                                selected={this.props.refferers.filters.createdEndDate}
                                onChange={this.onChangeDateInput('createdEndDate')}
                                maxDate={moment(this.props.refferers.filters.createdStartDate).add({ days: 30 }).toDate()}
                                required={this.props.refferers.filters.createdStartDate}
                            />
                        </div>
                        <div style={{ width: 250 }}>
                            <Select
                                isLoading={this.props.loadingCity || loading}
                                isDisabled={this.props.loadingCity || loading}
                                value={this.props.refferers.filters.city}
                                closeMenuOnSelect={true}
                                options={this.props.cities.map(item => ({
                                    value: item.id,
                                    label: item.name
                                }))}
                                onChange={this.setFilters('city')}
                                placeholder="Город"
                                noOptionsMessage={() => 'Нет городов'}
                            />
                        </div>
                        <button disabled={loading} onClick={this.onSearch} className="btn btn-primary">Найти</button>
                        <button disabled={loading} onClick={this.resetFilter} className="btn btn-danger">Сбросить</button>
                    </div>
                </div>
                <div className="kt-portlet__body">
                    {
                        loading || !result ? (
                            <div style={styles.loading}><Loader /></div>
                        ) : (
                            <div className="kt-widget5">
                            <div className="kt-widget5__item">
                                <div className="kt-widget5__content">
                                    <div className="kt-widget5__section">
                                        <p style={{ marginBottom: 0 }} className="kt-widget5__title">
                                           Всего регистраций
                                        </p>
                                    </div>
                                </div>
                                <div className="kt-widget5__content">
                                    <div style={{ paddingRight: 0 }} className="kt-widget5__stats">
                                        <span className="kt-widget5__number">{result ? result.usersCount : 0}</span>
                                        <span className="kt-widget5__votes">пользователей</span>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-widget5__item">
                                <div className="kt-widget5__content">
                                    <div className="kt-widget5__section">
                                        <p style={{ marginBottom: 0 }} className="kt-widget5__title">
                                            По приглашению
                                        </p>
                                    </div>
                                </div>
                                <div className="kt-widget5__content">
                                    <div style={{ paddingRight: 0 }} className="kt-widget5__stats">
                                        <span className="kt-widget5__number">{result ? result.usersReffer : 0}</span>
                                        <span className="kt-widget5__votes">пользователей</span>
                                    </div>
                                </div>
                            </div>
                            <div className="kt-widget5__item">
                                <div className="kt-widget5__content">
                                    <div className="kt-widget5__section">
                                        <p style={{ marginBottom: 0 }} className="kt-widget5__title">
                                           Прямая регистрация
                                        </p>
                                    </div>
                                </div>
                                <div className="kt-widget5__content">
                                    <div style={{ paddingRight: 0 }} className="kt-widget5__stats">
                                        <span className="kt-widget5__number">{result ? result.usersNoReffer : 0}</span>
                                        <span className="kt-widget5__votes">пользователей</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        )
                    }
                   
                </div>
            </div>
                        
        );
    }
}

const mapStateToProps = state => ({
    refferers: state.statistics.refferers,
    cities: state.handbook.cities,
    loadingCity: state.loaders.cities,
});

const mapDispatchToProps = {
    fetchReffersStat,
    fetchCities: () => fetchCities(true),
    setStatFilters: (name, filters) => setStatFilters(name, filters),
    resetStatFilters: (name) => resetStatFilters(name),
}

export default connect(mapStateToProps, mapDispatchToProps)(Refferers);