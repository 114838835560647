import React from 'react';
import { useSelector } from 'react-redux';
import { fromStore } from '../../../selectors';
import cn from 'classnames';
import { getImageUrl } from '../../../utils/common';
import { useDispatch } from 'react-redux';
import { deleteSlider, setActiveModalAction, updateSlider } from '../../../action';
import swal from 'sweetalert';
import ActionMenu from '../../../components/utils/ActionMenu';

export const ItemRow = ({ item }) => {
    const dispatch = useDispatch();
    const loaderUpdateSlider = useSelector(fromStore.loaderUpdateSliderSelector);
    const loaderDeleteSlider = useSelector(fromStore.loaderDeleteSliderSelector);
    const loaderUpdate = loaderUpdateSlider === item.id;
    const loadertDelete = loaderDeleteSlider === item.id;

    const onOpenModalGood = (id) => {
        const sliderId = id || 'new';
        dispatch(setActiveModalAction({ field: 'slider', value: sliderId }));
    }

    const onToogleActiveSlider = () => {
        const data = { active: !item.active };
        dispatch(updateSlider(item.id, data))
    };

    const onDeleteSlider = () => {
        swal({
            title: `Удаление слайдера`,
            text: `Вы действительно хотите удалить ${item.title}?`,
            icon: "warning",
            dangerMode: true,
            buttons: ["Отмена", "Удалить"],
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(deleteSlider(item.id));
            }
        });
    }

    const actions = [
        { name: 'Редактировать', icon: 'la la-edit', action: () => onOpenModalGood(item.id)},
        { name: 'Удалить', icon: 'la la-trash', action: () => onDeleteSlider()}
    ];    

    const renderBodyMobile = () => {
        return(            
            <div className='mobile_div_flex column padding10px'>
                <div className='mobile_div_flex'>
                    <span style={{ color: '#4f62b5' }}>ID: </span> <span style={{ color: '#000' }}>{item.id}</span> 
                </div>
                <div className='mobile_div_flex' style={{ justifyContent: 'center', alignItems: 'center', minHeight: '50px' }}>
                    <img width="100%" alt={item.name} src={getImageUrl(item.image)} />
                </div>
                <div className='mobile_div_flex'>
                    <div style={{ width: '50%' }}>
                        <span style={{ color: '#4f62b5' }}>Название: </span> 
                    </div>
                    <div className='mobile_div_flex wrap' style={{ width: '50%' }}>
                        <span style={{ color: '#000' }}>{item.title || '-'}</span> 
                    </div>
                </div>
                <div className='mobile_div_flex'>
                    <div style={{ width: '50%' }}>
                        <span style={{ color: '#4f62b5' }}>Описание: </span> 
                    </div>
                    <div className='mobile_div_flex wrap' style={{ width: '50%' }}>
                        <span style={{ color: '#000' }}>{item.description || '-'}</span> 
                    </div>
                </div>
                <div className='mobile_div_flex' style={{ width: 'calc(50% + 80px)', justifyContent: 'flex-end'}}>
                    <ActionMenu actions={actions}/>
                </div>
            </div>            
        )
    }

    if(window.innerWidth < 950)
        return (
            <tr key={`${item.id}_slider`} className="kt-datatable__row">
                {renderBodyMobile()}
            </tr>
        );
        

    return (
        <tr key={`${item.id}_slider`} className="kt-datatable__row">
            <td width="80px" className="kt-datatable__cell"><span>{item.id}</span></td>
            <td width="220px" className="kt-datatable__cell">
                <img width="200px" alt={item.name} src={getImageUrl(item.image)} />
            </td>
            <td className="kt-datatable__cell slider_title"><span>{item.title}</span></td>
            <td width="auto" className="kt-datatable__cell"><span>{item.description}</span></td>
            <td align='right' className="kt-datatable__cell table_action">
            <div style={{ width: '100%', height: '100%', display: 'flex', gap: '5px' }}>
                {!loadertDelete && !loaderUpdate && <ActionMenu actions={actions}/>} 
                {(loadertDelete || loaderUpdate) && <span style={{overflow: 'visible', position: 'relative', width: '80px'}}><button className='btn btn-sm btn-clean btn-icon btn-icon-md kt-spinner kt-spinner--center kt-spinner--sm kt-spinner--danger'></button></span> }
                <button
                    onClick={onToogleActiveSlider}
                    style={{ marginRight: '3px' }}
                    data-tip={item.active ? 'Выключить' : 'Включить'}
                    disabled={loaderUpdate || loadertDelete}
                    className={cn("btn btn-sm btn-icon btn-icon-md", {
                        'btn-danger': !item.active,
                        'btn-success': item.active,
                        'kt-spinner kt-spinner--center kt-spinner--sm kt-spinner--light': loaderUpdate
                    })}>
                    {!loaderUpdate && <i className="la la-power-off"></i>}
                </button>               
            </div>
            </td>
        </tr>
    );
}
